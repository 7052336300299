import { Base64 } from "js-base64";
import { ApiWithAuth } from "../service/api";

export const editorToApi = (value: any) => {
  return Base64.encode(value);
};

export const apiToEditor = (value: any) => {
  return Base64.decode(value);
};

export const createGoogleCalendarUrl = (
  eventTitle: string,
  startTime: string,
  endTime: string,
  details: string,
  location: string,
  url: string
) => {
  const encodedTitle = encodeURIComponent(eventTitle);
  const encodedDetails = encodeURIComponent(apiToEditor(details));
  const encodedLocation = encodeURIComponent(location);
  const encodedUrl = encodeURIComponent(url);

  window.open(
    `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedTitle}&dates=${startTime}/${endTime}&details=${encodedDetails}&location=${encodedLocation}&sf=true&output=xml&add=${encodedUrl}`
  );
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  const a = document.createElement("a");
  a.href = fileUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const sendEmail = (
  email: string,
  content?: { subject: string; body: string }
) => {
  window.location.href = `mailto:${email}?subject=${
    content?.subject ?? ""
  }&body=${content?.body ?? ""}`;
};

export const downloadCSV = (token?: string) => {
  if (token) {
    ApiWithAuth(token)
      .getUserListTemplate()
      .then((res: any) => {
        switch (res.status) {
          case 200:
            const csvData = res.data;
            const csvContent =
              "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
            const link = document.createElement("a");
            link.href = csvContent;
            link.setAttribute("download", "template.csv");
            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);

            break;
          default:
            alert(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
};
