import React, { useState } from "react";
import { IFormDetail } from "../types";

type IHeadConfig = {
  title: string;
  description: string;
};

export const SiteContext = React.createContext({
  handleModal: { close: () => {} },
  setHandleModalDetail: (value: { close: () => void }) => {},
  formDetail: undefined as IFormDetail | undefined,
  setFormDetail: (detail: IFormDetail | undefined) => {},

  headConfig: { title: "", description: "" },
  setHeadDetail: (value: IHeadConfig) => {},
});

const SiteProvider = (props: any) => {
  const { children } = props;
  const [handleModal, setHandleModal] = useState<any>({
    close: () => {},
  });
  const [headConfig, setHeadConfig] = useState<IHeadConfig>({
    title: "",
    description: "",
  });

  const [formDetail, setFormDetail] = useState<IFormDetail | undefined>();

  const setHeadDetail = (value: IHeadConfig) => {
    setHeadConfig(value);
  };
  const setHandleModalDetail = (value: { close: any }) => {
    setHandleModal(value);
  };

  const defaultValue = {
    handleModal,
    setHandleModalDetail,
    formDetail,
    setFormDetail,
    headConfig,
    setHeadDetail,
  };

  return (
    <SiteContext.Provider value={defaultValue}>{children}</SiteContext.Provider>
  );
};

export default SiteProvider;
