import React from "react";
import { Helmet } from 'react-helmet';

export interface ISeo {
  title: string;
  description?: string;
  keywords?: string;
  siteName?: string;
  url?: string;
  image?: string;
}

const SEO = (props: ISeo) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      {/* <link rel="shortcut icon" href="/sites/favicon.ico" /> */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
    </Helmet>
  );
};

export default SEO;
