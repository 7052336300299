import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import { Button, Stack, Grid } from "@mui/material";
import {
  RHFEditor,
  RHFTextField,
  WithTitle,
  RHFDateTimePicker,
  RHFRadioBtnGroup,
  RHFUploadFile,
} from "../../components";
import { Colors } from "../../themes";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import { IEvent } from "../../types";
import { ApiWithAuth } from "../../service/api";
import { UserContext } from "../../provider/user";
import { useNavigate, useParams } from "react-router-dom";
import { dateTimeToApi } from "../../utilities/date";

import { add, isAfter } from "date-fns";
import { apiToEditor, editorToApi } from "../../utilities/global";

const Wrapper = styled(Stack)`
  padding-bottom: 58px;
`;

const ButtonArea = styled(Stack)`
  background: ${Colors.W100};
  box-shadow: 0px -1px 0px 0px #5d636614;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
`;

const AddEvent = (props: { type: "edit" | "add"; data?: IEvent }) => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const id = params.id;
  const { token } = useContext(UserContext);

  const now = new Date();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const {
    handleSubmit,
    watch,
    formState: { isValid, errors },
    control,
    setValue,
    unregister,
    resetField,
    reset,
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data: any) => {
    if (token && id) {
      console.log(data.isLiveUrl==="true")
      const submitData = {
        name: data.name,
        content: editorToApi(data.content),
        place: data.place,
        email: data.email,
        address: data.address,
        eventTime: {
          start: dateTimeToApi(data.eventTime.start),
          end: dateTimeToApi(data.eventTime.end),
        },
        registrationTime: {
          start: dateTimeToApi(data.registrationTime.start),
          end: dateTimeToApi(data.registrationTime.end),
        },
        liveURL: data.isLiveUrl ? data.liveURL : "",
        limit: data.isLimit ? Number(data.limit) : 0,
        files: data.isFiles ? data.files : [],
      };
      try {
        const res =
          props.type === "edit" && projectId
            ? await ApiWithAuth(token).editEvent(projectId, id, {
                ...submitData,
                status: props?.data?.status,
              })
            : await ApiWithAuth(token).addEvent(id, submitData);
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            if (projectId) {
              navigate(`/projects/${projectId}/events/${id}`);
            } else {
              navigate(`/projects/${id}/events/${data?.item?.id}/form/add`);
            }
            break;
          default:
            alert(data.message);
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    let apiData = props.data;
    if (apiData) {
      setIsPageLoading(true);
      const data = {
        name: apiData.name,
        content: apiToEditor(apiData.content),
        place: apiData.place,
        email: apiData.email,
        address: apiData.address,
        liveURL: apiData.liveURL ?? "",
        eventTime: {
          start: new Date(apiData.eventTime.start),
          end: new Date(apiData.eventTime.end),
        },
        registrationTime: {
          start: new Date(apiData.registrationTime.start),
          end: new Date(apiData.registrationTime.end),
        },
        files: apiData.files.map((i) => {
          return { fileId: i.fileId, name: i.name };
        }),
        limit: apiData.limit ? Number(apiData.limit) : 0,
        status: apiData.status,
        isLiveUrl: !!apiData.liveURL,
        isFiles: apiData.files.length > 0,
        isLimit: !!apiData?.limit,
      };
      reset(data, { keepDirty: true });
      setIsPageLoading(false);
    }else{
      setIsPageLoading(false);
    }
  }, [props.data]);

  useEffect(() => {
    if (!watch("isLiveUrl")) {
      resetField("liveURL");
      unregister("liveURL");
    } else {
      resetField("liveURL");
    }
  }, [watch("isLiveUrl")]);

  useEffect(() => {
    if (!watch("isLimit")) {
      resetField("limit");
      unregister("limit");
    } else {
      resetField("limit");
    }
  }, [watch("isLimit")]);

  // useEffect(() => {
  //   if (!watch("isFiles")) {
  //     unregister("files");
  //     resetField("files");
  //   } else {
  //     resetField("files");
  //   }
  // }, [watch("isFiles")]);

  return (
    <Wrapper>
      {isPageLoading ? (
        <></>
      ) : (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          sx={{ marginLeft: `-16px !important` }}
        >
          <Grid item xs={6}>
            <WithTitle title="活動名稱 *">
              <RHFTextField
                name="name"
                control={control}
                type="text"
                placeholder="請輸入活動名稱"
                rules={{
                  required: true,
                }}
              />
            </WithTitle>
          </Grid>
          <Grid item xs={12}>
            <WithTitle title="活動內容 *">
              <RHFEditor
                {...{
                  name: "content",
                  control: control,
                  placeholder: "輸入文章內容",
                  rules: { required: true },
                }}
              />
            </WithTitle>
          </Grid>
          <Grid item xs={6}>
            <WithTitle title="活動地點 *">
              <RHFTextField
                name="place"
                control={control}
                type="text"
                placeholder="請輸入活動地點"
                rules={{
                  required: true,
                }}
              />
            </WithTitle>
          </Grid>

          <Grid item xs={6}>
            <WithTitle title="活動地址 *">
              <RHFTextField
                name="address"
                control={control}
                type="text"
                placeholder="請輸入活動地址"
                rules={{
                  required: true,
                }}
              />
            </WithTitle>
          </Grid>

          <Grid item xs={6}>
            <WithTitle title="活動日期 / 時間 *">
              <WithTitle title="開始 *">
                <RHFDateTimePicker
                  {...{
                    name: "eventTime.start",
                    control: control,
                    defaultValue: null,
                    minDateTime: now,
                    rules: {
                      require: true,
                      // valueAsDate: true,
                      validate: { before: (v: Date) => isAfter(v, now) },
                      shouldUnregister: true,
                    },
                    error: (errors.eventTime as any)?.start,
                  }}
                />
              </WithTitle>
            </WithTitle>
          </Grid>
          <Grid item xs={6}>
            <WithTitle title="　">
              <WithTitle title="結束 *">
                <RHFDateTimePicker
                  {...{
                    disabled: !watch("eventTime.start"),
                    name: "eventTime.end",
                    control: control,
                    //   disabled: !isStartDate,
                    defaultValue: null,
                    minDateTime: add(watch("eventTime.start"), { minutes: 1 }),
                    rules: {
                      require: true,
                      // valueAsDate: true,
                      validate: {
                        before: (v: Date) =>
                          isAfter(
                            v,
                            add(watch("eventTime.start"), { minutes: 1 })
                          ) && isAfter(v, now),
                      },
                      shouldUnregister: true,
                    },
                    error: (errors.eventTime as any)?.end,
                  }}
                />
              </WithTitle>
            </WithTitle>
          </Grid>
          <Grid item xs={6}>
            <WithTitle title="報名時間 *">
              <WithTitle title="開始 *">
                <RHFDateTimePicker
                  {...{
                    name: "registrationTime.start",
                    control: control,
                    defaultValue: null,
                    minDateTime: now,
                    rules: {
                      require: true,
                      // valueAsDate: true,
                      validate: { before: (v: Date) => isAfter(v, now) },
                      shouldUnregister: true,
                    },
                    error: (errors.registrationTime as any)?.start,
                  }}
                />
              </WithTitle>
            </WithTitle>
          </Grid>
          <Grid item xs={6}>
            <WithTitle title="　">
              <WithTitle title="結束 *">
                <RHFDateTimePicker
                  {...{
                    disabled: !watch("registrationTime.start"),
                    name: "registrationTime.end",
                    control: control,
                    //   disabled: !isStartDate,
                    defaultValue: null,
                    minDateTime: add(watch("registrationTime.start"), {
                      minutes: 1,
                    }),
                    rules: {
                      require: true,
                      // valueAsDate: true,
                      validate: {
                        before: (v: Date) =>
                          isAfter(
                            v,
                            add(watch("registrationTime.start"), {
                              minutes: 1,
                            })
                          ) && isAfter(v, now),
                      },
                      shouldUnregister: true,
                    },
                    error: (errors.registrationTime as any)?.end,
                  }}
                />
              </WithTitle>
            </WithTitle>
          </Grid>
          <Grid item xs={6}>
            <WithTitle title="連絡信箱 *">
              <RHFTextField
                name="email"
                control={control}
                type="text"
                placeholder="請輸入連絡信箱"
                rules={{
                  required: true,
                }}
              />
            </WithTitle>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <WithTitle title="是否提供線上觀看">
                <RHFRadioBtnGroup
                  className="form-indent-remove"
                  {...{
                    id: "isLiveUrl",
                    name: "isLiveUrl",
                    title: <></>,
                    list: [
                      {
                        option: {
                          value: true,
                          label: "是",
                        },
                        content: <></>,
                      },
                      {
                        option: {
                          value: false,
                          label: "否",
                        },
                        content: <></>,
                      },
                    ],
                    control: control,
                    defaultValue: false,
                    rules: {},
                  }}
                />
                <RHFTextField
                  name="liveURL"
                  control={control}
                  type="text"
                  placeholder="請輸入線上觀看網址"
                  disabled={watch("isLiveUrl") !== "true"}
                  rules={{
                    required: watch("isLiveUrl") === "true",
                  }}
                />
              </WithTitle>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={1.5}>
              <WithTitle title="報名人數限制">
                <RHFRadioBtnGroup
                  className="form-indent-remove"
                  {...{
                    id: "isLimit",
                    name: "isLimit",
                    title: <></>,
                    list: [
                      {
                        option: {
                          value: true,
                          label: "是",
                        },
                        content: <></>,
                      },
                      {
                        option: {
                          value: false,
                          label: "否",
                        },
                        content: <></>,
                      },
                    ],
                    control: control,
                    defaultValue: false,
                    rules: {},
                  }}
                />
                <RHFTextField
                  name="limit"
                  control={control}
                  type="number"
                  placeholder="請輸入人數限制"
                  disabled={watch("isLimit") !== "true"}
                  rules={{
                    required: watch("isLimit") === "true",
                  }}
                />
              </WithTitle>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={6}>
              <WithTitle title="是否提供檔案">
                <RHFRadioBtnGroup
                  className="form-indent-remove"
                  {...{
                    id: "isFiles",
                    name: "isFiles",
                    title: <></>,
                    list: [
                      {
                        option: {
                          value: true,
                          label: "是",
                        },
                        content: <></>,
                      },
                      {
                        option: {
                          value: false,
                          label: "否",
                        },
                        content: <></>,
                      },
                    ],
                    control: control,
                    defaultValue: false,
                    rules: {},
                  }}
                />
                <RHFUploadFile
                  name="files"
                  control={control}
                  setValue={setValue}
                  disabled={watch("isFiles") !== "true"}
                  rules={{
                    required: watch("isFiles") === "true",
                  }}
                  defaultValue={props?.data?.files.map((i) => {
                    return { fileId: i.fileId, name: i.name };
                  })}
                />
              </WithTitle>
            </Grid>
          </Grid>
        </Grid>
      )}
      <ButtonArea
        spacing={3}
        direction="row"
        justifyContent="end"
        alignItems="center"
        p={2}
      >
        <Button
          size="large"
          variant="text"
          onClick={() => {
            navigate(`/projects/${id}`);
          }}
        >
          取消
        </Button>
        <Button
          size="large"
          disabled={!isValid}
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        >
          下一步 <ArrowForwardRoundedIcon />
        </Button>
      </ButtonArea>
    </Wrapper>
  );
};

export default AddEvent;
