// Page.tsx
import React, { useState } from "react";
import { Button, TextField, Paper, Typography, Stack } from "@mui/material";
import styled from "styled-components";
import { Fonts } from "../../themes";

const Wrapper = styled(Stack)`
  padding: 24px 64px;
`;

const Title = styled(Fonts.h3())``;

const Page = () => {
  return (
    <Wrapper spacing={2}>
      <Title>管理頁面</Title>
    </Wrapper>
  );
};

export default Page;
