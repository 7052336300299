import styled from "styled-components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { ReactNode } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { Colors } from "../../themes";

type IRadio = {
  id?: string;
  name: string;
  title?: ReactNode;
  defaultValue?: string | number | boolean;
  list: {
    code: string;
    name: string;
  }[];
  placeholder?: string;
  hint?: string;
  disabled?: boolean;
  control: Control<FieldValues, object>;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rules: any;
  className?: string;
  direction?: "row" | "column";
};

const Wrapper = styled.div`
  max-width: min-width;
  padding-left: 8px;
  span {
    white-space: nowrap;
  }
  .form-indent-remove {
    margin-left: -8px;
  }
`;

const RHFColorPick = (props: IRadio) => {
  const { list } = props;
  return (
    <Wrapper>
      <FormLabel id={props.id}>{props.title}</FormLabel>
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={list[0].code}
        rules={props.rules}
        render={({ field: { onChange, value } }) => {
          let radioValue = value ?? props.defaultValue ?? list[0].code;
          return (
            <RadioGroup
              className={props.className}
              defaultValue={list[0].code}
              value={radioValue}
              onChange={onChange}
              sx={{ flexDirection: props.direction ?? "row" }}
            >
              {list
                .map((i) => {
                  return i.code;
                })
                .map((color, index) => (
                  <FormControlLabel
                    key={index}
                    value={color}
                    control={<Radio style={{ display: "none" }} />}
                    label={
                      <div
                        style={{
                          background: Colors.W100,
                          borderRadius: "50%",
                          display: "inline-block",
                          width: value === color ? "40px" : "40px",
                          height: value === color ? "40px" : "40px",
                          border:
                            value === color
                              ? `1px solid ${Colors.B30}`
                              : `1px solid ${Colors.W100}`,
                          padding: "2px",
                          margin: "4px 8px",
                        }}
                      >
                        <span
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                            background: color,
                            borderRadius: "50%",
                          }}
                        ></span>
                      </div>
                    }
                  />
                ))}
            </RadioGroup>
          );
        }}
      />
    </Wrapper>
  );
};

export default RHFColorPick;
