import { Fonts, Colors } from ".";
import styled from "styled-components";

const Styles = {
  Title: () => styled(Fonts.h2())`
    max-width: 60%;
  `,
  SubTitle: () => styled(Fonts.h3())`
    padding-bottom: 12px;
  `,
  FormTitle: () => styled(Fonts.h5())`
    .required {
      color: ${Colors.Required};
    }
  `,
  FormText: () => styled(Fonts.p16())`
    &.no-wrap {
      white-space: nowrap;
    }
  `,
  FormSubTitle: () => styled(Fonts.h6())`
    padding-top: 12px;
  `,
  Info: () => styled(Fonts.info())`
    color: ${Colors.B50};
  `,
  DetailContent: () => styled.div`
    padding: 16px 20px 0;
    background: ${Colors.G1};
    border-radius: 8px;
  `,
  DetailTitle: () => styled(Fonts.h5())`
    position: relative;
    padding-left: 16px;
    &:before {
      content: " ";
      position: absolute;
      left: 4px;
      width: 4px;
      height: 100%;
      border-radius: 8px;
      background: ${Colors.Green}
    }
  `,
  EditContent: () => styled.form`
    padding: 16px 20px 0;
    background: ${Colors.G1};
    border-radius: 8px;
  `,
  MainWrapper: () => styled.div`
    .MuiSelect-select,
    .MuiOutlinedInput-input,
    .MuiInputBase-multiline {
      padding: 9px 16px;
    }
    .MuiOutlinedInput-input {
      padding: 9px 0px 9px 16px;
      font-size: 14px;
    }
  `,
  Content: () => styled.div`
    padding: 16px 20px 0;
  `,
  EditImg: {
    FormAreaImg: () => styled.div`
      position: relative;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }
      width: 100%;
    `,
    ContentDiv: () => styled.div`
      display: flex;
      .link {
        color: ${Colors.P50};
        padding-left: 4px;
      }
      .btn {
        color: ${Colors.T3};
      }
      .link:hover {
        cursor: pointer;
      }
    `,
    CropperDiv: () => styled.div`
      min-height: 308px;
      max-width: 430px;
      margin: 0 auto;
      position: relative;
      display: grid;
      .reactEasyCrop_CropArea {
        color: ${Colors.W50};
      }
    `,
    ZoomDiv: () => styled.div`
      margin: 4px auto;
      display: flex;
      max-width: 430px;
      align-items: center;
      width: 60%;
      svg {
        padding: 0 16px;
      }
    `,
  },
};
export default Styles;
