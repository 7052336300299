// AddForm.tsx
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { Stack, TableRow, IconButton, Grid, Box } from "@mui/material";
import { Seo, Progress, AddForm } from "../../../../components";
import { Colors, Fonts } from "../../../../themes";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import { IEvent } from "../../../../types";
import { ApiWithAuth } from "../../../../service/api";
import { UserContext } from "../../../../provider/user";
import { useNavigate, useParams } from "react-router-dom";
import { timeRangeProcessor2 } from "../../../../utilities/date";

const Wrapper = styled(Stack)`
  padding: 24px 64px;
  .MuiTableContainer-root {
    max-height: inherit;
  }
`;

const Title = styled(Fonts.h3())``;

const BackTitle = styled(Fonts.h4())``;

const BoxStatus = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: end;
  svg {
    width: 18px;
    fill: ${Colors.G40};
  }
  &.active p {
    color: ${Colors.B40};
  }
  &.active svg {
    fill: ${Colors.B40};
  }
`;

const BoxStatusTitle = styled(Fonts.p16())`
  padding-right: 8px;
  color: ${Colors.G40};
`;

const Content = styled(Stack)``;

const DetailBox = styled(Box)``;

const BoxTitle = styled(Fonts.h6())`
  padding-bottom: 8px;
`;

const BoxText = styled(Fonts.p14())`
  padding-bottom: 4px;
`;

const AddFormPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId as string;
  const id = params.id as string;
  const mode = params.mode ?? "form";
  const { token } = useContext(UserContext);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState<string>();

  const [detailData, setDetailData] = useState<IEvent>();

  const statusList = [
    // { id: "select", title: "建立活動" },
    { id: "event", title: "活動資訊" },
    { id: "form", title: "報名表" },
    { id: "website", title: "活動網站" },
    { id: "deploy", title: "發布活動" },
  ];

  const getProjectList = () => {
    if (token) {
      ApiWithAuth(token)
        .getProjects({})
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.items.find(
                (i: { projectId: string | undefined }) =>
                  i.projectId === projectId
              );
              setTitle(data.title);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  const getEventDetail = () => {
    if (token) {
      ApiWithAuth(token)
        .getEventDetail(projectId, id)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.item;
              setDetailData(data);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  const detailBoxList = detailData
    ? [
        {
          id: "name",
          title: "活動名稱",
          textList: [`${detailData.name}`],
        },
        {
          id: "address",
          title: "活動地址",
          textList: [`${detailData.address}`],
        },
        { id: "place", title: "活動地點", textList: [`${detailData.place}`] },
        {
          id: "eventTime",
          title: "活動日期/時間",
          textList: [
            timeRangeProcessor2(
              detailData.eventTime.start,
              detailData.eventTime.end
            ),
          ],
        },
        { id: "email", title: "連絡信箱", textList: [`${detailData.email}`] },
        {
          id: "limit",
          title: "人數限制",
          textList: [`${detailData.registration.limit}`],
        },
      ]
    : [];

  useEffect(() => {
    if (token) {
      getProjectList();
      getEventDetail();
      //   getList();
    }
  }, [token]);

  return (
    <Wrapper spacing={3}>
      {isPageLoading ? (
        <Progress />
      ) : (
        <>
          <Seo title="新增表單" description="新增表單" />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(`/projects/${projectId}`);
                }}
              >
                <ArrowBackIosNewRoundedIcon fontSize="small" />
              </IconButton>
              <BackTitle>{title}</BackTitle>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {statusList.map((item, index) => (
                <BoxStatus
                  key={item.id}
                  className={mode === item.id ? "active" : ""}
                >
                  <BoxStatusTitle>{item.title}</BoxStatusTitle>
                  {index !== statusList.length - 1 && (
                    <ArrowForwardIosRoundedIcon />
                  )}
                </BoxStatus>
              ))}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Title>建立報名表</Title>
          </Stack>

          {isLoading ? (
            <Progress />
          ) : (
            <Content>
              <Grid
                item
                container
                xs={7}
                rowSpacing={3}
                columnSpacing={3}
                sx={{ paddingBottom: 2 }}
              >
                {detailBoxList &&
                  detailBoxList.map((i) => (
                    <Grid item xs={4}>
                      <DetailBox key={i.id}>
                        <BoxTitle>{i.title}</BoxTitle>
                        {i.textList.map((text) => (
                          <BoxText key={text}>{text}</BoxText>
                        ))}
                      </DetailBox>
                    </Grid>
                  ))}
              </Grid>
              <AddForm />
            </Content>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default AddFormPage;
