// Setting.tsx
import {  Stack } from "@mui/material";
import styled from "styled-components";
import { Fonts } from "../../themes";

const Wrapper = styled(Stack)`
  padding: 24px 64px;
`;

const Title = styled(Fonts.h3())``;

const Setting = () => {
  return (
    <Wrapper spacing={2}>
      <Title>設定</Title>
    </Wrapper>
  );
};

export default Setting;
