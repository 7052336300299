/* eslint-disable react-hooks/exhaustive-deps */
// Edit.tsx
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { Stack, TableRow, IconButton, Box } from "@mui/material";
import { Progress, Seo, AddEvent } from "../../../components";
import { Colors, Fonts } from "../../../themes";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import { IEvent } from "../../../types";
import { ApiWithAuth } from "../../../service/api";
import { UserContext } from "../../../provider/user";
import { useNavigate, useParams } from "react-router-dom";

const Wrapper = styled(Stack)`
  padding: 24px 64px;
  .MuiTableContainer-root {
    max-height: inherit;
  }
`;

const Title = styled(Fonts.h3())``;

const BackTitle = styled(Fonts.h4())``;

const BoxStatus = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: end;
  svg {
    width: 18px;
    fill: ${Colors.G40};
  }
  &.active p {
    color: ${Colors.B40};
  }
  &.active svg {
    fill: ${Colors.B40};
  }
`;

const BoxStatusTitle = styled(Fonts.p16())`
  padding-right: 8px;
  color: ${Colors.G40};
`;


const Edit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const id = params.id;
  const mode = params.mode ?? "event";
  const { token } = useContext(UserContext);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState<string>();

  const [detailData, setDetailData] = useState<IEvent>();

  const statusList = [
    // { id: "select", title: "建立活動" },
    { id: "event", title: "活動資訊" },
    { id: "form", title: "報名表" },
    { id: "website", title: "活動網站" },
    { id: "deploy", title: "發布活動" },
  ];

  const getProjectList = () => {
    if (token) {
      ApiWithAuth(token)
        .getProjects({})
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.items.find(
                (i: { projectId: string | undefined }) =>
                  i.projectId === projectId
              );
              setTitle(data.title);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  const getEventDetail = () => {
    if (token && projectId && id) {
      ApiWithAuth(token)
        .getEventDetail(projectId, id)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.item;
              setDetailData(data);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  useEffect(() => {
    if (token) {
      getProjectList();
      getEventDetail();
      //   getList();
    }
  }, [token]);

  return (
    <Wrapper spacing={3}>
      {isPageLoading ? (
        <Progress />
      ) : (
        <>
          <Seo title="編輯活動" description="編輯活動" />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                color="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIosNewRoundedIcon fontSize="small" />
              </IconButton>
              <BackTitle>{title}</BackTitle>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {statusList.map((item, index) => (
                <BoxStatus
                  key={item.id}
                  className={mode === item.id ? "active" : ""}
                >
                  <BoxStatusTitle>{item.title}</BoxStatusTitle>
                  {index !== statusList.length - 1 && (
                    <ArrowForwardIosRoundedIcon />
                  )}
                </BoxStatus>
              ))}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Title>編輯活動資訊</Title>
          </Stack>

          {isLoading ? (
            <Progress />
          ) : mode === "event" ? (
            <AddEvent data={detailData} type="edit" />
          ) : (
            <></>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Edit;
