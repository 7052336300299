/* eslint-disable react-hooks/exhaustive-deps */
// AddForm.tsx
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import { Button, Stack, IconButton, Grid, Box, Divider } from "@mui/material";
import {
  Seo,
  Progress,
  RHFPTextField,
  RHFPRadioBtnGroup,
} from "../../../../components";
import { Colors, Fonts } from "../../../../themes";
import { FBIcon, LinkedinIcon } from "../../../../images";

import { IEvent, IFormDetail, IQuestion } from "../../../../types";
import { ApiNoAuth, ApiWithAuth } from "../../../../service/api";
import { UserContext } from "../../../../provider/user";
import { useNavigate, useParams } from "react-router-dom";
import { timeRangeProcessor2 } from "../../../../utilities/date";

import { apiToEditor } from "../../../../utilities/global";
import { Container } from "@mui/system";
import { SiteContext } from "../../../../provider/site";

const Wrapper = styled(Container)`
  background: ${Colors.Gray};
  .MuiTableContainer-root {
    max-height: inherit;
  }
`;

const Header = styled.div``;

const Banner = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
`;

const DetailBox = styled(Box)`
  p {
    margin: 0;
  }
`;

const BoxTitle = styled(Fonts.h5())`
  padding-bottom: 8px;
`;

const BoxText = styled(Fonts.p14())`
  padding-bottom: 4px;
`;

const QItem = styled(Grid)``;

const QTitle = styled(Fonts.h5())`
  padding-bottom: 12px;
`;

const ConfirmText = styled(Fonts.h5())``;

const Footer = styled(Stack)``;

const FooterText = styled(Fonts.p12())`
  color: ${Colors.W100};
`;

const FormPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId as string;
  const id = params.id as string;
  const { token } = useContext(UserContext);
  const { formDetail, setFormDetail } = useContext(SiteContext);
  const colorList = [
    {
      name: "Black",
      code: Colors.Primary,
    },
    {
      name: "Orange",
      code: Colors.Orange,
    },
    {
      name: "Blue",
      code: Colors.B40,
    },
  ];

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [detailData, setDetailData] = useState<IEvent>();
  const [formDetailShow, setFormDetailShow] = useState<IFormDetail | undefined>(
    formDetail ?? undefined
  );

  const {
    formState: { isDirty, isValid },
    control,
    register,
    reset,
  } = useForm({ mode: "onChange" });

  const getEventDetail = () => {
    if (token) {
      ApiWithAuth(token)
        .getEventDetail(projectId, id)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.item;
              setDetailData(data);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  const getFormDetail = async () => {
    if (token && id) {
      try {
        const res = await ApiWithAuth(token).getEventForm(projectId, id);
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            if (!data.item?.status?.isPublished) {
              navigate(`/projects/${projectId}/events/${id}/form/add`);
            }
            const detail = {
              banner: data.item.banner,
              questions: data.item.questions.map((i: IQuestion) => {
                return {
                  text: i.text,
                  type: i.type,
                  require: i.required,
                  options:
                    i.type === "text"
                      ? undefined
                      : i.options?.map((i) => {
                          return { text: i.text };
                        }),
                };
              }),
              colors: colorList.map((i) => {
                return { ...i, selected: i.code === data.item.color };
              }),
              status: data.item.status,
            };
            setFormDetailShow(detail);
            setFormDetail(detail);
            break;
          default:
            // alert(res.status + ": 表單不存在，請先建立報名表！");
            navigate(`/projects/${projectId}/events/${id}/form/add`);
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const detailBoxList = detailData
    ? [
        {
          id: "name",
          title: "活動名稱",
          textList: [`${detailData.name}`],
        },
        {
          id: "address",
          title: "活動地址",
          textList: [`${detailData.address}`],
        },
        { id: "place", title: "活動地點", textList: [`${detailData.place}`] },
        {
          id: "eventTime",
          title: "活動日期/時間",
          textList: [
            timeRangeProcessor2(
              detailData.eventTime.start,
              detailData.eventTime.end
            ),
          ],
        },
        { id: "email", title: "連絡信箱", textList: [`${detailData.email}`] },
        {
          id: "limit",
          title: "人數限制",
          textList: [`${detailData.registration.limit}`],
        },
      ]
    : [];

  useEffect(() => {
    if (token) {
      getEventDetail();
      //   getList();
    }
  }, [token]);

  useEffect(() => {
    if (formDetailShow?.questions) {
      formDetailShow?.questions?.map((i, index) => {
        register(`q${index}`, { required: i.required });
      });
    }
  }, [formDetailShow]);

  useEffect(() => {
    if (!formDetail) {
      getFormDetail();
    }
  }, [token, formDetail]);

  console.log(formDetailShow?.status);
  return (
    <Wrapper>
      {isPageLoading || !detailData || !formDetailShow ? (
        <Progress />
      ) : (
        <>
          <Seo
            title={detailData.name + "報名表"}
            description={detailData.name + "報名表"}
          />
          <Header
            style={{
              background:
                formDetailShow.colors.find((i) => i.selected === true)?.code ??
                Colors.B100,
              width: "100%",
              height: "80px",
            }}
          />
          <Banner>
            <img
              src={ApiNoAuth.getImg(formDetailShow.banner.fileId)}
              alt="banner"
            />
          </Banner>
          <Stack justifyContent="center" alignItems="center">
            <Grid
              container
              lg={8}
              md={10}
              xs={12}
              rowSpacing={3}
              columnSpacing={3}
              justifyItems="center"
              alignItems="center"
              sx={{ p: 2, py: 4 }}
            >
              {detailBoxList &&
                detailBoxList.map((i) => (
                  <Grid item xs={6} md={4}>
                    <DetailBox key={i.id}>
                      <BoxTitle>{i.title}</BoxTitle>
                      {i.textList.map((text) => (
                        <BoxText key={text}>{text}</BoxText>
                      ))}
                    </DetailBox>
                  </Grid>
                ))}
              <Grid item xs={12}>
                <DetailBox>
                  <BoxTitle>活動簡介</BoxTitle>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: apiToEditor(detailData.content),
                    }}
                  />
                </DetailBox>
              </Grid>
            </Grid>
          </Stack>
          <Divider />
          <Stack justifyContent="center" alignItems="center">
            <Grid
              container
              lg={8}
              md={10}
              xs={12}
              rowSpacing={3}
              columnSpacing={3}
              justifyItems="center"
              alignItems="center"
              sx={{ p: 2, py: 4 }}
            >
              {formDetailShow.questions.map((item, index) => (
                <QItem key={item.text} item xs={12}>
                  <QTitle>
                    {index + 1}. {item.text}
                  </QTitle>
                  {item.type === "text" ? (
                    <RHFPTextField
                      control={control}
                      variant="standard"
                      name={`q${index}`}
                      placeholder="回答"
                      rules={{ require: item.required }}
                    />
                  ) : item.options ? (
                    <RHFPRadioBtnGroup
                      className="form-indent-remove"
                      {...{
                        id: `q${index}`,
                        name: `q${index}`,
                        title: <></>,
                        list: item.options.map((i) => {
                          return {
                            value: i.text,
                            label: i.text,
                            disabled: false,
                          };
                        }),
                        control: control,
                        defaultValue: "undefined",
                        rules: { require: item.required },
                      }}
                    />
                  ) : null}
                </QItem>
              ))}
            </Grid>
          </Stack>
          <Divider />
          <Stack justifyContent="center" alignItems="center">
            <Grid
              container
              lg={8}
              md={10}
              xs={12}
              justifyItems="center"
              alignItems="center"
              sx={{ p: 2, py: 4 }}
            >
              <Grid item xs={12}>
                <ConfirmText>
                  本報名表之個人資料之蒐集、處理及利用，係作為保險、手冊及聯繫等與本活動相關事項之用，由本單位依相關法令規定妥善保存保密。報名資料請確切由本人填寫，如偽造身份資料，一經查獲自負法律責任。
                  本同意書說明當您按下「送出問卷」，即表示您已閱讀、瞭解，並同意接受本同意書之所有內容及其後修改變更規定。
                </ConfirmText>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                sx={{ py: 4 }}
              >
                <Button size="large" variant="text" onClick={() => reset()}>
                  重置
                </Button>
                <Button size="large" disabled={!isDirty || !isValid}>
                  送出
                </Button>
              </Grid>
            </Grid>
          </Stack>
          <Footer
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              background:
                formDetailShow.colors.find((i) => i.selected === true)?.code ??
                Colors.B100,
            }}
            py={2}
          >
            <FooterText>Powered By Acompany © 2021</FooterText>
            <FooterText>Privacy Plolicy</FooterText>
            <IconButton>
              <FBIcon />
            </IconButton>
            <IconButton>
              <LinkedinIcon />
            </IconButton>
          </Footer>
        </>
      )}
    </Wrapper>
  );
};

export default FormPage;
