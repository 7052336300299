export const Colors = {
  // White Color
  W100: "rgba(255, 255, 255, 1)",
  W80: "rgba(255, 255, 255, 0.8)",
  W50: "rgba(255, 255, 255, 0.5)",
  W20: "rgba(255, 255, 255, 0.2)",
  W10: "rgba(255,255,255,0.1)",
  W5: "rgba(255,255,255,0.05)",
  //Black Color
  B100: "rgba(0,0,0,1)",
  B80: "rgba(0,0,0,0.8)",
  B50: "rgba(0,0,0,0.5)",
  B30: "rgba(0,0,0,0.3)",
  B20: "rgba(0,0,0,0.2)",
  B10: "rgba(0,0,0,0.1)",
  B07: "rgba(0,0,0,0.07)",
  B05: "rgba(0,0,0,0.05)",

  //Gray Color
  G5: "#333848",
  G4: "#81838C",
  G3: "#BEC0C5",
  G2: "#D8D8D8",
  G1: "#F7F7F7",

  G20: "#F2F2F2",
  G30: "#E0E0E0",
  G40: "#BDBDBD",
  G50: "#828282",
  Gray: "#FBFBFB",

  // T
  T5: "#828282",
  T4: "",
  T3: "",
  T2: "",
  T1: "",

  //Gray Color
  B1: "#E6F1FE",
  B2: "#CEE4FE",
  B3: "#4095FF",
  B4: "#187AF3",

  Bule20: "#82CFFF",
  B40: "#1192E8",

  R40: "#D43216",
  //Primary Color
  Blue: "#187AF3",
  Light_Blue: "#E6F1FE",
  Red: "#E35B3F",
  Required: "#DC4D3B",
  Light_Red: "#F7E9E8",
  Background_Green: "#DDEAE4",
  Green: "#36BA7E",
  SwitchGreen: "#4CD964",
  Dark_Green: "#10A368",
  Dark_Green_Hover: "rgb(11, 114, 72)",
  Light_Green: "#F0FAF5",
  Yellow: "#F8C303",
  Dark_Yellow: "#EFAD4E",
  Light_Yellow: "#FDF1E0",

  BackGround: "#F9FBFD",
  HeaderBackground: "#FFFFFF",

  TitleBlack: "#262626",
  SubTitleGray: "#A3A5A6",

  Primary: "#262626",
  Orange: "#FF832B",
};

export default Colors;
