// AddForm.tsx
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useFieldArray, useForm } from "react-hook-form";

import {
  Button,
  Stack,
  IconButton,
  Grid,
  Container,
  TextField,
} from "@mui/material";
import { Progress, RHFContent, AddList, Seo } from "../../../../components";
import { Colors, Fonts } from "../../../../themes";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import DesktopMacOutlinedIcon from "@mui/icons-material/DesktopMacOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import {
  FBIcon,
  LinkedinIcon,
  CopyIcon,
  ShareCopyIcon,
  ShareFBIcon,
  ShareLineIcon,
} from "../../../../images";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { IEvent, IFormDetail } from "../../../../types";
import { ApiNoAuth, ApiWithAuth } from "../../../../service/api";
import { UserContext } from "../../../../provider/user";
import { useNavigate, useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { timeRangeProcessor2 } from "../../../../utilities/date";

import {
  apiToEditor,
  createGoogleCalendarUrl,
  downloadFile,
  editorToApi,
  sendEmail,
} from "../../../../utilities/global";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { confirm } from "../../../../components/ModalF";
import { SiteContext } from "../../../../provider/site";

interface ContentProps {
  background: string;
}

const Wrapper = styled.div`
  .MuiIconButton-root {
    border-radius: 8px;
  }
  .drag {
    position: absolute;
    left: 16px;
    top: 24px;
  }
  .drag:hover {
    cursor: pointer;
  }
`;

const Toolbar = styled(Stack)`
  position: fixed;
  width: 100vw;
  height: 74px;
  background: ${Colors.W100};
  border: 1px solid ${Colors.B05};
  z-index: 105;
`;

const ToolArea = styled(Stack)`
  height: 100%;
  border-right: 1px solid ${Colors.B05};
  padding: 16px;
  &:last-child {
    border-right: 0px;
  }
`;
const HrefInput = styled(TextField)`
  .MuiOutlinedInput-root input {
    width: 100%;
    height: 42px;
    padding: 0 20px;
    border-radius: 33px;
    background: ${Colors.Gray} !important;
  }
`;

const Header = styled.div``;
const BannerArea = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px solid ${Colors.B10};
  img {
    width: 100%;
    height: auto;
  }
`;

const Content = styled(Container)<ContentProps>`
  padding: 74px 0 36px;
  background: ${Colors.Gray};
  .reverse {
    color: ${Colors.W100};
  }
  .MuiButton-containedPrimary {
    background: ${(props) => props.background};
  }
`;

const DragContent = styled(Stack)`
  position: relative;
  width: 100%;
  padding: 24px 16px;
  border: 1px solid ${Colors.B10};
`;

const Title = styled(Fonts.h3())`
  justify-self: center;
  padding-bottom: 36px;
`;

const Text = styled(Fonts.p14())``;

const ShowContentArea = styled.div`
  width: 70%;
  text-align: center;
  padding: 24px 16px;
  p {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 8px;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

const DetailBox = styled(Grid)`
  display: grid;
  gap: 12px;
  justify-content: center;
  padding: 16px;
  height: fit-content;
`;

const IconArea = styled.div`
  justify-self: center;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.W100};
  background: ${(props) => props.color || Colors.W100};
  border-radius: 100%;
`;

const Border = styled.div`
  width: 20px;
  height: 1px;
  background: ${Colors.G50};
  justify-self: center;
`;

const BoxTitle = styled(Fonts.h5())`
  text-align: center;
  padding-bottom: 8px;
`;

const BoxText = styled(Fonts.p14())`
  text-align: center;
  padding-bottom: 4px;
`;

const Footer = styled(Stack)``;

const FooterText = styled(Fonts.p12())`
  color: ${Colors.W100};
`;

const ModalTitle = styled(Fonts.h4())`
  padding-top: 0px;
`;

const ModalDes = styled(Fonts.p14())`
  padding-bottom: 8px;
`;

const CopyUrlArea = styled(Stack)`
  position: relative;
  background: ${Colors.G20};
  padding: 4px 8px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 8px !important;
  .MuiIconButton-root {
    position: relative;
    top: inherit;
    right: inherit;
  }
  p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 1;
    cursor: default;
  }
`;

const QRCodeArea = styled(Stack)`
  background: ${Colors.G20};
  border: 1px solid ${Colors.G50};
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  margin-bottom: 8px !important;
`;

const ShareArea = styled(Stack)`
  .MuiIconButton-root {
    position: relative;
    top: inherit;
    right: inherit;
  }
`;

const EditWebsite = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId as string;
  const id = params.id as string;
  const { token } = useContext(UserContext);
  const { handleModal } = useContext(SiteContext);
  const DOMAIN =
    process.env.REACT_APP_API_DOMAIN ??
    `${window.location.protocol}//${window.location.host}`;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [detailData, setDetailData] = useState();
  const [eventDetailData, setEventDetailData] = useState<IEvent>();
  const [formData, setFormData] = useState<IFormDetail>();

  const [view, setView] = useState<"lg" | "md" | "sm" | "xs">("lg");

  const {
    handleSubmit,
    watch,
    formState: { isDirty, isValid, dirtyFields, errors },
    control,
    setValue,
    unregister,
    register,
    reset,
    trigger,
  } = useForm({ mode: "onChange" });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `elements`,
  });

  const onSubmit = async (data: any) => {
    if (data && token && id) {
      const submitData = {
        item: {
          elements: data.elements
            ?.map(
              (item: {
                blockName: any;
                attrs: { title?: any; content?: any; fileId?: any };
              }) => {
                switch (item.blockName) {
                  case "core/title":
                    return {
                      blockName: item.blockName,
                      attrs: { title: item.attrs.title },
                    };
                  case "core/content":
                    return {
                      blockName: item.blockName,
                      attrs: { content: editorToApi(item.attrs.content) },
                    };
                  case "core/image":
                    return {
                      blockName: item.blockName,
                      attrs: { fileId: item.attrs.fileId },
                    };
                  default:
                    return null; // Or some other default object
                }
              }
            )
            .filter(Boolean),
          status: data.status,
        },
      };
      try {
        setIsLoading(true);
        const res = await ApiWithAuth(token).editWebsite(
          projectId,
          id,
          submitData
        );
        const resData = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            if (data?.status?.isSiteCreated) {
              confirm({
                title: "",
                contentArea: (
                  <>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                      sx={{ width: "100%", marginTop: -4 }}
                    >
                      <ModalTitle>活動頁面已建立</ModalTitle>
                      <ModalDes>分享你的活動</ModalDes>
                      <CopyUrlArea
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Text>
                          {DOMAIN}/projects/{projectId}/events/{id}/website/page
                        </Text>
                        <IconButton
                          onClick={() => {
                            document.addEventListener("copy", handleCopyUrl);
                            document.execCommand("copy");
                          }}
                        >
                          <CopyIcon />
                        </IconButton>
                      </CopyUrlArea>
                      <QRCodeArea alignItems="center" justifyContent="center">
                        <QRCodeSVG
                          value={`${DOMAIN}/projects/${projectId}/events/${id}/website/page`}
                        />
                      </QRCodeArea>
                      <ShareArea alignItems="center" justifyContent="center">
                        <Text>分享此網站</Text>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-center"
                        >
                          <IconButton
                            onClick={() => {
                              document.addEventListener("copy", handleCopy);
                              document.execCommand("copy");
                            }}
                          >
                            <ShareCopyIcon />
                          </IconButton>
                          <IconButton onClick={() => shareToFacebook()}>
                            <ShareFBIcon />
                          </IconButton>
                          <IconButton onClick={() => shareToLine()}>
                            <ShareLineIcon />
                          </IconButton>
                        </Stack>
                      </ShareArea>
                    </Stack>
                  </>
                ),
                buttonText: "前往邀請參與者",
                cancelText: "暫時不要",
                handleClose: () => {
                  handleModal.close();
                  navigate(`/projects/${projectId}/events/${id}`);
                },
                handleConfirm: () => {
                  confirm({
                    className: "y-scroll",
                    title: "",
                    contentArea: <AddList projectId={projectId} id={id} />,
                    noButton: true,
                    noCancel: true,
                    handleClose: () => {
                      navigate(`/projects/${projectId}/events/${id}`);
                    },
                    handleConfirm: () => {},
                  });
                },
              });
            }
            setIsLoading(false);
            break;
          default:
            alert(data.message ?? "儲存失敗！");
            setIsLoading(false);
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getEventDetail = () => {
    if (token) {
      ApiWithAuth(token)
        .getEventDetail(projectId, id)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.item;
              setEventDetailData(data);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  const getFormDetail = async () => {
    if (token && projectId && id) {
      try {
        const res = await ApiWithAuth(token).getEventForm(projectId, id);
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            setFormData(data.item);
            break;
          default:
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getWebsite = async () => {
    if (token && projectId && id) {
      try {
        const res = await ApiWithAuth(token).getWebsite(projectId, id);
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            setDetailData(data.item);
            if (data.item?.elements?.length > 0) {
              reset({
                elements: data.item.elements?.map(
                  (item: {
                    blockName: any;
                    attrs: { title?: any; content?: any; fileId?: any };
                  }) => {
                    switch (item.blockName) {
                      case "core/content":
                        return {
                          blockName: item.blockName,
                          attrs: { content: apiToEditor(item.attrs.content) },
                        };
                      case "core/image":
                        return {
                          blockName: item.blockName,
                          attrs: { fileId: item.attrs.fileId },
                        };
                      default:
                        return {
                          blockName: item.blockName,
                          attrs: item.attrs,
                        };
                    }
                  }
                ),
                status: data.item.status,
              });
            } else {
              reset({
                elements: [
                  {
                    blockName: "core/content",
                    attrs: {
                      content: "華信投顧 - 邱鼎泰分析獅",
                    },
                  },
                ],
              });
            }
            break;
          default:
            reset({
              elements: [
                {
                  blockName: "core/content",
                  attrs: {
                    content: "華信投顧 - 邱鼎泰分析獅",
                  },
                },
              ],
            });
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleCopyUrl = (e: ClipboardEvent) => {
    e.clipboardData &&
      e.clipboardData.setData(
        "text/plain",
        `${DOMAIN}/projects/${projectId}/events/${id}/website/page`
      );
    e.preventDefault();
    document.removeEventListener("copy", handleCopy);
  };

  const handleCopy = (e: ClipboardEvent) => {
    const text = `我們誠摯地邀請您參加我們即將舉辦的${eventDetailData?.name}。
    我們非常期待您的參與，以下是活動網址：${`${DOMAIN}/projects/${projectId}/events/${id}/website/page`}`;
    e.clipboardData && e.clipboardData.setData("text/plain", text);
    e.preventDefault();
    document.removeEventListener("copy", handleCopy);
  };

  const shareToFacebook = () => {
    const url = `${DOMAIN}/projects/${projectId}/events/${id}/website/page`;
    const encodedUrl = encodeURIComponent(url);
    const text = `我們誠摯地邀請您參加我們即將舉辦的${
      eventDetailData?.name ?? "活動"
    }。
我們非常期待您的參與，以下是活動網址：`;
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&t=${encodeURIComponent(
      text
    )}`;
    window.open(shareUrl);
  };

  const shareToLine = () => {
    const url = `${DOMAIN}/projects/${projectId}/events/${id}/website/page`;
    const text = `我們誠摯地邀請您參加我們即將舉辦的${
      eventDetailData?.name ?? "活動"
    }。
我們非常期待您的參與，以下是活動網址：`;
    const shareUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
      url
    )}&text=${encodeURIComponent(text)}`;
    window.open(shareUrl);
  };

  useEffect(() => {
    if (token) {
      getEventDetail();
      getFormDetail();
      getWebsite();
    }
  }, [token]);

  return (
    <Wrapper>
      <Seo title="編輯活動網站" description="編輯活動網站" />
      <Toolbar direction="row" alignItems="center">
        <Container maxWidth="lg">
          <Stack direction="row" alignItems="center">
            <ToolArea
              direction="row"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <HrefInput
                // variant="filled"
                sx={{
                  border: "none",
                  "& fieldset": { border: "none" },
                  width: "100%",
                }}
                value={`${DOMAIN}/projects/${projectId}/events/${id}/website/page`}
              />
            </ToolArea>
            <ToolArea direction="row" alignItems="center">
              <IconButton onClick={() => setView("lg")}>
                <DesktopMacOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => setView("xs")}>
                <PhoneAndroidOutlinedIcon />
              </IconButton>
            </ToolArea>
            <ToolArea direction="row" alignItems="center">
              <IconButton disabled onClick={() => {}}>
                <UndoIcon />
              </IconButton>
              <IconButton disabled onClick={() => {}}>
                <RedoIcon />
              </IconButton>
            </ToolArea>
            <ToolArea direction="row" alignItems="center">
              <IconButton disabled onClick={() => {}}>
                <FormatBoldIcon />
              </IconButton>
              <IconButton disabled onClick={() => {}}>
                <FormatColorTextIcon />
              </IconButton>
            </ToolArea>
            <ToolArea direction="row" alignItems="center">
              <Button
                variant="text"
                color="error"
                size="large"
                onClick={() =>
                  confirm({
                    title: "確定要結束編輯？",
                    des: "您的草稿已自動儲存",
                    // buttonColor: "error",
                    handleConfirm: async () => {
                      await setValue("status.isSiteCreated", false);
                      await handleSubmit(onSubmit)();
                      navigate(`/projects/${projectId}`);
                    },
                    isLoading: isLoading,
                    buttonText: "確定",
                    cancelText: "繼續編輯",
                    handleClose: () => {},
                  })
                }
              >
                離開
              </Button>
              <Button variant="text" color="info" size="large" disabled>
                AI 圖片生成
              </Button>
              <Button
                variant="text"
                color="info"
                size="large"
                onClick={() => {
                  setValue("status.isSiteCreated", false);
                  handleSubmit(onSubmit)();
                }}
              >
                儲存
              </Button>
              <Button
                variant="text"
                color="info"
                size="large"
                onClick={() =>
                  window.open(
                    `/projects/${projectId}/events/${id}/website/preview`
                  )
                }
              >
                預覽
              </Button>
              <Button
                size="large"
                sx={{ ml: 1 }}
                onClick={() => {
                  setValue("status.isSiteCreated", true);
                  handleSubmit(onSubmit)();
                }}
              >
                下一步 <ArrowForwardRoundedIcon />
              </Button>
            </ToolArea>
          </Stack>
        </Container>
      </Toolbar>
      {isPageLoading || !formData || !eventDetailData ? (
        <Progress />
      ) : (
        <Content
          maxWidth={view}
          background={
            formData?.colors.find((i) => i.selected === true)?.code ??
            Colors.B100
          }
        >
          <Header
            style={{
              background:
                formData.colors.find((i) => i.selected === true)?.code ??
                Colors.B100,
              width: "100%",
              height: "80px",
            }}
          />
          <BannerArea>
            {/* <DragIndicatorRoundedIcon className="drag" /> */}
            <img src={ApiNoAuth.getImg(formData.banner.fileId)} alt="banner" />
          </BannerArea>
          <DragContent justifyContent="center" alignItems="center">
            {/* <DragIndicatorRoundedIcon className="drag" /> */}
            <ShowContentArea
              dangerouslySetInnerHTML={{
                __html: apiToEditor(eventDetailData.content),
              }}
            ></ShowContentArea>
          </DragContent>
          <DragContent
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            {/* <DragIndicatorRoundedIcon className="drag" /> */}
            {/* {eventDetailData?.liveURL?.length > 0 && ( */}
            {eventDetailData.liveURL?.length > 0 ? (
              <Button
                variant="outlined"
                size="large"
                sx={{
                  width: 160,
                  color:
                    formData.colors.find((i) => i.selected === true)?.code ??
                    Colors.B100,
                  borderColor:
                    formData.colors.find((i) => i.selected === true)?.code ??
                    Colors.B100,
                }}
                onClick={() => window.open(eventDetailData.liveURL)}
              >
                進入直播間
              </Button>
            ) : null}
            <Button
              size="large"
              sx={{
                width: 160,
              }}
            >
              立即報名
            </Button>
          </DragContent>
          <DragContent justifyContent="center" alignItems="center">
            {/* <DragIndicatorRoundedIcon className="drag" /> */}
            <Title>活動資訊</Title>
            <Grid container justifyItems="center" justifyContent="center">
              <DetailBox item container xs={view === "xs" ? 12 : 3}>
                <IconArea
                  color={
                    formData.colors.find((i) => i.selected === true)?.code ??
                    Colors.B100
                  }
                >
                  <FmdGoodOutlinedIcon />
                </IconArea>
                <BoxTitle>活動地址</BoxTitle>
                <Border />
                <BoxText>{eventDetailData.address}</BoxText>
              </DetailBox>
              <DetailBox item container xs={view === "xs" ? 12 : 3}>
                <IconArea
                  color={
                    formData.colors.find((i) => i.selected === true)?.code ??
                    Colors.B100
                  }
                >
                  <EventOutlinedIcon />
                </IconArea>
                <BoxTitle>活動時間</BoxTitle>
                <Border />
                <BoxText>
                  {timeRangeProcessor2(
                    eventDetailData.eventTime.start,
                    eventDetailData.eventTime.end
                  )}
                </BoxText>
                <Button
                  variant="text"
                  onClick={() => {
                    createGoogleCalendarUrl(
                      eventDetailData.name,
                      eventDetailData.eventTime.start,
                      eventDetailData.eventTime.end,
                      eventDetailData.content,
                      eventDetailData.place,
                      `/${projectId}/${id}`
                    );
                  }}
                  sx={{
                    width: "min-content",
                    justifySelf: "center",
                    borderBottom: "1px solid",
                    borderRadius: 0,
                    padding: "4px 0 5px",
                    "&:hover": {
                      background: "inherit",
                      borderBottom: "2px solid",
                      padding: "4px 0",
                    },
                  }}
                >
                  <AddRoundedIcon />
                  加入行事曆
                </Button>
              </DetailBox>
              {eventDetailData.files.length > 0 ? (
                <DetailBox item container xs={view === "xs" ? 12 : 3}>
                  <IconArea
                    color={
                      formData.colors.find((i) => i.selected === true)?.code ??
                      Colors.B100
                    }
                  >
                    <FileDownloadOutlinedIcon />
                  </IconArea>
                  <BoxTitle>檔案下載</BoxTitle>
                  <Border />
                  {eventDetailData.files.map((i) => (
                    <Button
                      variant="text"
                      sx={{
                        width: "min-content",
                        justifySelf: "center",
                        borderBottom: "1px solid",
                        borderRadius: 0,
                        padding: "4px 0 5px",
                        "&:hover": {
                          background: "inherit",
                          borderBottom: "2px solid",
                          padding: "4px 0",
                        },
                      }}
                      onClick={() =>
                        downloadFile(ApiNoAuth.getFile(i.fileId), i.name)
                      }
                    >
                      <FileDownloadOutlinedIcon />
                      {i?.name}
                    </Button>
                  ))}
                </DetailBox>
              ) : null}
            </Grid>
          </DragContent>
          <DragContent
            justifyItems="center"
            justifyContent="center"
            alignItems="center"
            sx={{
              background:
                formData.colors.find((i) => i.selected === true)?.code ??
                Colors.B100,
            }}
          >
            {/* <DragIndicatorRoundedIcon className="drag" /> */}
            <Button
              size="large"
              sx={{
                width: 200,
                border: `1px solid`,
                mb: 2,
              }}
              color="secondary"
              variant="contained"
              onClick={() => sendEmail(eventDetailData?.email)}
            >
              聯絡我們
            </Button>
            <BoxTitle className="reverse">{eventDetailData.email}</BoxTitle>
          </DragContent>
          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!result.destination) {
                return;
              }
              let arr = Array.from(fields);
              const [remove] = arr.splice(source.index, 1);
              if (destination) {
                arr.splice(destination.index, 0, remove);
                setValue("elements", arr);
              }
            }}
          >
            <Droppable droppableId="select">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <DragContent
                            direction="row"
                            justifyItems="center"
                            justifyContent="center"
                            spacing={2}
                            // alignItems="center"
                            sx={{ background: Colors.Gray }}
                          >
                            <IconButton
                              {...provided.dragHandleProps}
                              sx={{ height: "min-content" }}
                            >
                              <DragIndicatorRoundedIcon />
                            </IconButton>
                            <RHFContent
                              name="elements"
                              index={index}
                              control={control}
                              unregister={unregister}
                              onCopy={() => {}}
                              onDelete={() => {
                                remove(index);
                              }}
                              register={register}
                              setValue={setValue}
                              defaultValue={watch(`elements.${index}`)}
                            />
                          </DragContent>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Stack justifyContent="center" alignItems="center" p={4}>
            <Button
              size="large"
              sx={{
                width: "70%",
              }}
              variant="outlined"
              onClick={() =>
                append({
                  blockName: "core/title",
                  attrs: {
                    title: ``,
                  },
                })
              }
            >
              <AddRoundedIcon />
              新增內容
            </Button>
          </Stack>
          <Footer
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              background:
                formData.colors.find((i) => i.selected === true)?.code ??
                Colors.B100,
            }}
            py={2}
          >
            <FooterText>Powered By Acompany © 2021</FooterText>
            <FooterText>Privacy Plolicy</FooterText>
            <IconButton>
              <FBIcon />
            </IconButton>
            <IconButton>
              <LinkedinIcon />
            </IconButton>
          </Footer>
        </Content>
      )}
    </Wrapper>
  );
};

export default EditWebsite;
