import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { Colors, Fonts } from "../themes";
import { Divider, ListItemAvatar, ListSubheader, Stack } from "@mui/material";

import { ProjectIcon, PageIcon, MemberIcon, SettingIcon } from "../images";

type ISideMenu = {
  // list: { id: string; href: string; label: string; type: string }[] | undefined;
};

const Wrapper = styled.div`
  background: ${Colors.HeaderBackground} !important;
  width: 180px;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 84px;
  overflow-y: scroll;
  z-index: 100;
  padding-bottom: 70px;
  display: flex !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  .MuiList-root {
    width: 100%;
  }
  img {
    width: 15px;
    height: 15px;
    padding-right: 4px;
  }
  z-index: 99;
`;

const Option = styled.div`
  padding: 16px 16px 16px 20px;
  svg {
    padding-right: 4px;
  }
  &.Mui-selected {
    border-left: 4px solid ${Colors.B4};
    padding: 16px;
    background-color: inherit;
  }
  &.Mui-selected:hover {
    border-left: 4px solid ${Colors.B4};
    background-color: inherit;
  }
  &.Mui-selected p {
    color: ${Colors.B40};
    font-height: 600;
  }
  &.Mui-selected svg path {
    fill: ${Colors.B40};
  }
  .MuiTouchRipple-child {
    background-color: ${Colors.B2};
  }
`;

const OptionTitle = styled(Fonts.p16())`
  color: ${Colors.T5};
`;

const SideMenu = (props: ISideMenu) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const nowCategory = pathname.split("/")[1];

  const list = [
    {
      id: "pages",
      href: "/pages",
      label: "管理頁面",
    },
    {
      id: "projects",
      href: "/projects",
      label: "專案管理",
    },
    {
      id: "lists",
      href: "/lists",
      label: "名單",
    },
    {
      id: "setting",
      href: "/setting",
      label: "設定",
    },
  ];

  const renderImage = (id: string) => {
    switch (id) {
      case "pages":
        return <PageIcon />;
      case "projects":
        return <ProjectIcon />;
      case "lists":
        return <MemberIcon />;
      case "setting":
        return <SettingIcon />;
      default:
        return null;
    }
  };
  return (
    <Stack
      component={Wrapper}
      // justifyContent="flex-start"
      // alignItems="flex-start"
      // spacing={2}
    >
      <List>
        {list?.map(
          (i, index) =>
            i.id !== "overview" && (
              <ListItemButton
                key={i.id}
                component={Option}
                selected={nowCategory === i.id}
                onClick={() => {
                  navigate(`${i.href}`);
                }}
              >
                {renderImage(i.id)}
                <OptionTitle>{i.label}</OptionTitle>
              </ListItemButton>
            )
        )}
      </List>
    </Stack>
  );
};

export default SideMenu;
