import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useFieldArray, useForm } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Button, Stack, Grid, Divider, CircularProgress } from "@mui/material";
import {
  Progress,
  WithTitle,
  RHFUploadImage,
  RHFColorPick,
  RHFFormQA,
} from "../../components";
import { Colors } from "../../themes";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import { ApiWithAuth } from "../../service/api";
import { SiteContext } from "../../provider/site";
import { useNavigate, useParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import { UserContext } from "../../provider/user";
import { IQuestion } from "../../types";

const Wrapper = styled(Stack)`
  padding-bottom: 58px;
`;

const ButtonArea = styled(Stack)`
  background: ${Colors.W100};
  box-shadow: 0px -1px 0px 0px #5d636614;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
`;

const QAArea = styled(Grid)``;

const AddForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId as string;
  const id = params.id;
  const { token } = useContext(UserContext);
  const { formDetail, setFormDetail } = useContext(SiteContext);
  const colorList = [
    {
      name: "Black",
      code: Colors.Primary,
    },
    {
      name: "Orange",
      code: Colors.Orange,
    },
    {
      name: "Blue",
      code: Colors.B40,
    },
  ];

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    watch,
    formState: { isValid },
    control,
    setValue,
    unregister,
    register,
    reset,
    trigger,
  } = useForm({ mode: "onChange" });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `questions`,
    rules: {
      required: true,
    },
  });

  const getFormDetail = async () => {
    if (token && id && !formDetail) {
      try {
        setIsPageLoading(true);
        const res = await ApiWithAuth(token).getEventForm(projectId, id);
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            setFormDetail(data.item);
            break;
          default:
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onSubmit = async (data: any) => {
    const submitData = {
      banner: data.banner,
      questions: data.questions.map((i: IQuestion) => {
        return {
          text: i.text,
          type: i.type,
          require: i.required,
          options:
            i.type === "text"
              ? undefined
              : i.options?.map((i) => {
                  return { text: i.text };
                }),
        };
      }),
      colors: colorList.map((i) => {
        return { ...i, selected: i.code === data.color };
      }),
      status: {
        isPublished: false,
      },
    };

    setFormDetail(submitData);
    navigate(`/projects/${projectId}/events/${id}/form/preview`);
  };

  const onStore = async (data: any) => {
    const submitData = {
      banner: data.banner,
      questions: data.questions.map((i: IQuestion) => {
        return {
          text: i.text,
          type: i.type,
          require: i.required,
          options:
            i.type === "text"
              ? undefined
              : i.options?.map((i) => {
                  return { text: i.text };
                }),
        };
      }),
      colors: colorList.map((i) => {
        return { ...i, selected: i.code === data.color };
      }),
      status: {
        isPublished: false,
      },
    };
    try {
      setIsLoading(true);
      if (token && id) {
        const res = await ApiWithAuth(token).addEventForm(
          projectId,
          id,
          submitData
        );
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            setFormDetail(submitData);
            navigate(`/projects/${projectId}/events/${id}/form/preview`);
            break;
          default:
            alert(data.message ?? "儲存失敗！");
            setIsLoading(false);
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFormDetail();
  }, []);

  useEffect(() => {
    if (formDetail) {
      reset({
        ...formDetail,
        color: formDetail.colors.find((i) => i.selected === true)?.code,
      });
      setIsPageLoading(false);
    } else {
      setIsPageLoading(false);
      reset({
        banner: {
          fileId: undefined,
        },
        questions: [
          {
            text: "姓名",
            type: "text",
            required: true,
          },
          {
            text: "手機",
            type: "text",
            required: true,
          },
          {
            text: "Email",
            type: "text",
            required: true,
          },
          {
            text: "任職單位",
            type: "text",
            required: true,
          },
          {
            text: "職稱",
            type: "text",
            required: true,
          },
          // {
          //   text: "可參加時段",
          //   type: "singleChoice",
          //   required: true,
          // },
        ],
      });
      register("banner.fileId", { required: true });
    }
  }, [formDetail]);

  useEffect(() => {
    trigger("banner.fileId");
  }, [watch("banner.fileId")]);

  return (
    <Wrapper>
      {isPageLoading ? (
        <Progress />
      ) : (
        <>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={2}
            sx={{ marginLeft: `-16px !important` }}
          >
            <Grid item xs={12}>
              <Grid item xs={6}>
                <WithTitle title="上傳報名表 Banner *">
                  <RHFUploadImage
                    name="banner.fileId"
                    control={control}
                    setValue={setValue}
                    des="建議尺寸：1280x700"
                    aspectRatio={1280 / 700}
                    rules={{ require: true }}
                  />
                </WithTitle>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6}>
                <WithTitle title="頁面色調 *">
                  <RHFColorPick
                    name="color"
                    list={colorList}
                    control={control}
                    rules={{ require: true }}
                  />
                </WithTitle>
              </Grid>
            </Grid>
          </Grid>
          <QAArea
            container
            rowSpacing={1}
            columnSpacing={2}
            pb={2}
            sx={{ marginLeft: `-16px !important` }}
          >
            <Grid item xs={12}>
              <DragDropContext
                onDragEnd={(result) => {
                  const { source, destination } = result;
                  if (!result.destination) {
                    return;
                  }
                  let arr = Array.from(fields);
                  const [remove] = arr.splice(source.index, 1);
                  if (destination) {
                    arr.splice(destination.index, 0, remove);
                    setValue("questions", arr);
                    trigger("questions");
                  }
                }}
              >
                <Droppable droppableId="select">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {fields.map((i: any, index) => (
                        <Draggable key={i.id} draggableId={i.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <RHFFormQA
                                key={i.id}
                                name="questions"
                                control={control}
                                index={index}
                                unregister={unregister}
                                onCopy={() => {
                                  append(watch(`questions.${index}`));
                                }}
                                onDelete={() => remove(index)}
                                register={register}
                                defaultValue={watch(`questions.${index}`)}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </QAArea>
          <Divider />
          <Grid container py={3}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  append({
                    text: undefined,
                    type: "text",
                    required: false,
                  });
                }}
              >
                <AddIcon />
                新增問題
              </Button>
            </Grid>
          </Grid>
          <ButtonArea
            spacing={3}
            direction="row"
            justifyContent="end"
            alignItems="center"
            p={2}
            px={8}
          >
            {/* <Button
              size="large"
              variant="text"
              onClick={() => {
                handleSubmit(onStore)();
                // navigate(`/projects/${id}`);
              }}
            >
              {isLoading ? (
                <CircularProgress sx={{ width: "24px" }} />
              ) : (
                "儲存並結束"
              )}
            </Button> */}
            <Button
              size="large"
              disabled={!isValid}
              onClick={() => {
                handleSubmit(onStore)();
                // handleSubmit(onSubmit)();
              }}
            >
              建立 <ArrowForwardRoundedIcon />
            </Button>
          </ButtonArea>
        </>
      )}
    </Wrapper>
  );
};

export default AddForm;
