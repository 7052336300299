import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Fonts, Styles } from "../../themes";
import { ISelectOption } from "../../types";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

type ISelect = {
  title?: string;
  list: ISelectOption[];
  value: string | number;
  onStatusChange: (e: any) => void;
  disabled?: boolean;
};

const Wrapper = styled(Styles.MainWrapper())`
  svg {
    padding-right: 8px;
  }
`;

const Title = styled(Fonts.h6())``;

const SelectArea = (props: ISelect) => {
  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <FormControl>
        <Select
          value={props.value}
          onChange={props.onStatusChange}
          disabled={props.disabled}
          IconComponent={() => <ExpandMoreRoundedIcon />}
        >
          {props.list.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  );
};

export default SelectArea;
