import styled from "styled-components";
import { Fonts, Colors, Metrics } from "../themes";
import {
  Button,
  Dialog,
  Stack,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import { IModal } from "../types";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Wrapper = styled.div`
  padding: 24px;
  min-width: 503px;
  .retry-alert {
    height: inherit;
  }

  &.no-padding {
    padding: 16px 0;
  }
  &.for-scroll {
    height: 864px;
  }

  &.event-detail {
    padding-bottom: 0;
  }

  &.fit-content {
    height: fit-content;
  }
  &.full-height {
    height: 100%;
  }

  .close {
    padding: 8px;
    position: absolute;
    top: 17px;
    right: 12px;
  }
  position: relative;
  height: min-content;
  overflow: hidden;

  @media (max-width: ${Metrics.tablet}) {
    padding: 24px 16px;
    width: 100%;
    .close {
      top: 21px;
    }
  }
  @media (max-width: ${Metrics.mobile}) {
    .close {
      top: 17px;
    }
    .MuiButton-root {
      font-size: 14px;
    }
  }

  .terms-wrap {
    height: calc(100vh - 250px);
    overflow-y: auto;
    width: 100%;
  }

  &.y-scroll {
    overflow: hidden scroll;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* For Firefox */
  * {
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /* For Firefox */
  }
`;

const Title = styled(Fonts.h2())`
  padding-top: 48px;
  padding-bottom: 12px;
  @media (max-width: ${Metrics.tablet}) {
    padding-bottom: ${Metrics.px4};
  }

  &.no-padding {
    padding-bottom: 0;
  }
`;

const Des = styled(Fonts.p16())`
  padding-bottom: 16px;
  @media (max-width: ${Metrics.tablet}) {
    padding-bottom: ${Metrics.px1};
  }
`;

const Modal = (props: IModal) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullScreen={props.needFullScreen ? fullScreen : false}
      sx={
        props.needFullScreen
          ? {
              "& .MuiDialog-paper": {
                borderRadius: { xs: 0, md: "16px" },
              },
            }
          : {
              "& .MuiDialog-paper": {
                marginInline: { xs: "16px", md: "24px" },
              },
            }
      }
    >
      <Wrapper className={props.className}>
        {props?.disabledCloseButton != true && (
          <IconButton
            className="close"
            aria-label="delete"
            size="large"
            disabled={props?.isLoading}
            onClick={props.handleClose}
            sx={{
              width: { xs: "40px", md: "46px" },
              height: { xs: "40px", md: "46px" },
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        )}
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={props.className?.includes("mailbox") ? {} : { height: "100%" }}
        >
          <Title
            className={!props.contentArea && !props.des ? "no-padding" : ""}
          >
            {props.title}
          </Title>
          {props.des && <Des>{props.des}</Des>}
          {props.contentArea}
          {!props.noButton && (
            <>
              {props.buttonArea ? (
                props.buttonArea
              ) : (
                <Stack
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                  spacing={{
                    xs: 1,
                    md: 2,
                  }}
                  sx={{
                    mt: { xs: "12px", md: "24px" },
                    width: "100%",
                    borderTop: `1px solid ${Colors.B30}`,
                    pt: "24px",
                  }}
                >
                  {!props.noCancel && (
                    <Button
                      size="large"
                      variant="text"
                      color={props.buttonColor ? props.buttonColor : "primary"}
                      onClick={props.handleClose}
                      disabled={props?.isLoading || props.disabled}
                      sx={{
                        minWidth: { xs: "93px" },
                        height: { xs: "60px" },
                        fontSize: "18px",
                      }}
                    >
                      {props.cancelText ?? "取消"}
                    </Button>
                  )}
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={props?.isLoading}
                    onClick={props.handleConfirm}
                    sx={{
                      minWidth: { xs: "93px" },
                      height: { xs: "60px" },
                      fontSize: "18px",
                    }}
                  >
                    {props?.isLoading ? (
                      <CircularProgress />
                    ) : (
                      props.buttonText ?? "確定"
                    )}
                  </Button>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Wrapper>
    </Dialog>
  );
};

export default Modal;
