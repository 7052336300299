import { create } from "apisauce";
import {
  IBodyDeleteUser,
  IBodyEvent,
  IBodyForm,
  IBodyUser,
  IBodyWebsite,
  IParamProjectList,
} from "../types/api";
const DOMAIN =
  process.env.REACT_APP_API_DOMAIN ??
  `${window.location.protocol}//${window.location.host}`;
const API_VERSION = "v1";
const API_URL = `${DOMAIN}/api/${API_VERSION}`;
// const TIMEOUT = 5000;

export const ApiNoAuth = (function () {
  const api = create({
    baseURL: API_URL,
    headers: {
      //   "Cache-Control": "no-cache",
      Accept: "application/json",
    },
    // timeout: TIMEOUT,
  });

  return {
    login: async (userid: string, password: string) =>
      api.post("/auth/login", {
        userid: userid,
        password: password,
      }),
    uploadFile: async (formData: any, onUploadProgress?: any) =>
      api.post(`/files`, formData, onUploadProgress),
    getImg: (id: string) => `${API_URL}/files/image/${id}`,
    getFile: (id: string) => `${API_URL}/files/${id}`,
  };
})();

export const ApiWithAuth = (token: string) =>
  (function () {
    const api = create({
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // timeout: TIMEOUT,
    });

    return {
      getUserData: () => api.get("/auth/me"),

      // * project
      getProjects: (params: IParamProjectList) => api.get("/projects", params),

      // * events
      getEvents: (projectId: string) =>
        api.get(`/projects/${projectId}/events`),
      getEventDetail: (projectId: string, eventId: string) =>
        api.get(`/projects/${projectId}/events/${eventId}`),
      addEvent: (projectId: string, form: IBodyEvent) =>
        api.post(`/projects/${projectId}/events`, form),
      editEvent: (projectId: string, id: string, form: IBodyEvent) =>
        api.put(`/projects/${projectId}/events/${id}`, form),
      deleteEvent: (projectId: string, id: string) =>
        api.delete(`/projects/${projectId}/events/${id}`),

      // * event list
      getEventMemberList: (projectId: string, eventId?: string) =>
        api.get(`/projects/${projectId}/lists?size=999999`),

      // * event form
      addEventForm: (projectId: string, eventId: string, body: IBodyForm) =>
        api.put(`/projects/${projectId}/events/${eventId}/forms`, body),
      getEventForm: (projectId: string, eventId: string) =>
        api.get(`/projects/${projectId}/events/${eventId}/forms`),

      // * website
      getWebsite: (projectId: string, id: string) =>
        api.get(`/projects/${projectId}/events/${id}/page`),
      editWebsite: (projectId: string, id: string, form: IBodyWebsite) =>
        api.put(`/projects/${projectId}/events/${id}/page`, form),

      // * project user
      getMemberList: (projectId: string, eventId: string) =>
        api.get(`/projects/${projectId}/lists?size=999999`),
      getUserListTemplate: () => api.get("/lists/template"),
      addUsers: (
        projectId: string,
        body: { items: { name: string; phone: string; email: string }[] }
      ) => api.post(`/projects/${projectId}/lists/bulk`, body),
      updateUsers: (projectId: string, body: { items: IBodyUser[] }) =>
        api.put(`/projects/${projectId}/lists/bulk`, body),
      deleteUsers: (projectId: string, items: IBodyDeleteUser[]) =>
        api.delete(
          `/projects/${projectId}/lists/bulk`,
          {},
          { data: { items } }
        ),

      // * send
      sendMail: (
        projectId: string,
        eventId: string,
        body: { items: { name: string; email: string }[] }
      ) =>
        api.post(`/projects/${projectId}/events/${eventId}/invitations`, body),
    };
  })();
