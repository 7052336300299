import React from "react";
import ReactDOM from "react-dom/client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Layout } from "./components";

import "./index.css";
import {
  Login,
  ListsPage,
  AdminPage,
  ProjectPage,
  ProjectEvents,
  ProjectAddEvent,
  ProjectEditEvent,
  AddForm,
  FormPreview,
  FormPage,
  EventDetail,
  SettingPage,
  EditWebsite,
  WebsitePreview,
  WebsitePage,
} from "./containers";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { resetServerContext } from "react-beautiful-dnd";

import { ThemeProvider } from "@mui/material/styles";
import SiteProvider from "./provider/site";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { MountPoint } from "./utilities/modal";

import theme from "./muiTheme";
import UserProvider from "./provider/user";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

resetServerContext();

root.render(
  <UserProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <SiteProvider>
              <BrowserRouter>
                <Layout>
                  <Routes>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/lists" element={<ListsPage />}></Route>
                    <Route path="/pages" element={<AdminPage />}></Route>
                    <Route path="/projects" element={<ProjectPage />}></Route>
                    <Route
                      path="/projects/:id"
                      element={<ProjectEvents />}
                    ></Route>
                    <Route
                      path="/projects/:projectId/:id"
                      element={<EventDetail />}
                    ></Route>

                    <Route
                      path="/projects/:id/events/add"
                      element={<ProjectAddEvent />}
                    ></Route>
                    <Route
                      path="/projects/:projectId/events/:id/edit"
                      element={<ProjectEditEvent />}
                    ></Route>
                    <Route
                      path="/projects/:projectId/events/:id"
                      element={<EventDetail />}
                    ></Route>
                    <Route
                      path="/projects/:projectId/events/:id/form/add"
                      element={<AddForm />}
                    ></Route>
                    <Route
                      path="/projects/:projectId/events/:id/form/preview"
                      element={<FormPreview />}
                    ></Route>
                     <Route
                      path="/projects/:projectId/events/:id/form/page"
                      element={<FormPage />}
                    ></Route>
                    <Route
                      path="/projects/:projectId/events/:id/website"
                      element={<EditWebsite />}
                    ></Route>
                    <Route
                      path="/projects/:projectId/events/:id/website/preview"
                      element={<WebsitePreview />}
                    ></Route>
                    <Route
                      path="/projects/:projectId/events/:id/website/page"
                      element={<WebsitePage />}
                    ></Route>
                    <Route path="/setting" element={<SettingPage />}></Route>
                  </Routes>
                  <MountPoint />
                </Layout>
              </BrowserRouter>
            </SiteProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
