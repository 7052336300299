import { parseISO, format, formatISO } from "date-fns";
import { ISchedule } from "../types";
import { zhTW } from "date-fns/locale";

export const dateProcessor = (date: string | undefined) =>
  date ? format(parseISO(`${date}`), "yyyy/LL/dd HH:mm") : null;

export const timeProcessor = (date: string | undefined) =>
  date ? format(parseISO(`${date}`), "HH:mm") : null;

export const dateProcessor2 = (date: string | undefined) =>
  date
    ? format(parseISO(`${date}`), "yyyy/LL/dd (EEEEE) HH:mm ", { locale: zhTW })
    : null;

export const pickerDateProcessor = (date: string) =>
  format(new Date(date), "yyyy-LL-dd");

export const pickerDateTimeProcessor = (date: string) =>
  formatISO(new Date(date));

export const dateToString = (date: Date | null) =>
  date ? format(date, "yyyy/LL/dd") : "";

export const dateTimeToString = (date: Date | null) =>
  date ? format(date, "yyyyLLddHHmm") : "";

export const dateTimeToApi = (date: Date) => {
  return date?.toISOString()?.slice(0, 19) + 'Z';
};

export const scheduleToText = (schedule: ISchedule | null) => {
  let start_date = schedule?.start_date
    ? dateProcessor(schedule?.start_date)
    : "無上架時間";
  let due_date = schedule?.due_date
    ? dateProcessor(schedule?.due_date)
    : "無下架時間";
  return `${start_date}~${due_date}`;
};

export const timeRangeProcessor = (start_date: string, due_date: string) => {
  if (pickerDateProcessor(start_date) === pickerDateProcessor(due_date)) {
    return dateProcessor(start_date) + "-" + timeProcessor(due_date);
  } else {
    return dateProcessor(start_date) + "-" + dateProcessor(due_date);
  }
};

export const timeRangeProcessor2 = (start_date: string, due_date: string) => {
  if (pickerDateProcessor(start_date) === pickerDateProcessor(due_date)) {
    return dateProcessor2(start_date) + "-" + timeProcessor(due_date);
  } else {
    return dateProcessor2(start_date) + "-" + dateProcessor2(due_date);
  }
};