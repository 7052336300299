// Login.tsx
import { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import styled from "styled-components";
import { WithTitle, RHFTextField } from "../components";
import { useForm } from "react-hook-form";
import { ApiNoAuth } from "../service/api";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Login = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    watch,
    formState: { isDirty, isValid, dirtyFields, errors },
    control,
  } = useForm({ mode: "onChange" });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    ApiNoAuth.login(data.email, data.password)
      .then((res: any) => {
        switch (res.status) {
          case 200:
            cookies.set("token", res.data.accessToken, {
              path: "/",
              sameSite: true,
            });
            navigate("/projects");
            setIsLoading(false);
            break;
          default:
            console.log(res);
            setIsLoading(false);
            alert(res.data.message);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <WithTitle title="Email*" className="textfield-l-max">
          <RHFTextField
            name="email"
            control={control}
            type="text"
            placeholder="請輸入 email"
            rules={{
              required: true,
            }}
          />
        </WithTitle>

        <WithTitle title="密碼*" className="textfield-l-max">
          <RHFTextField
            name="password"
            control={control}
            type="password"
            placeholder="請輸入密碼"
            rules={{
              required: true,
            }}
          />
        </WithTitle>
        {/* <TextField
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
        <Button
          className="textfield-l-max"
          variant="contained"
          color="primary"
          type="submit"
          style={{ width: "100%", marginTop: 16 }}
          disabled={!isValid || isLoading}
        >
          {isLoading ? <CircularProgress sx={{ width: "24px" }} /> : "登入"}
        </Button>
      </Form>
    </Wrapper>
  );
};

export default Login;
