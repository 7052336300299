// AddForm.tsx
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Stack, IconButton, Grid } from "@mui/material";
import { Seo, Progress } from "../../../../components";
import { Colors, Fonts } from "../../../../themes";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { FBIcon, LinkedinIcon } from "../../../../images";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { IEvent, IFormDetail, IWebsite } from "../../../../types";
import { ApiNoAuth, ApiWithAuth } from "../../../../service/api";
import { UserContext } from "../../../../provider/user";
import { useNavigate, useParams } from "react-router-dom";
import { timeRangeProcessor2 } from "../../../../utilities/date";

import {
  apiToEditor,
  createGoogleCalendarUrl,
  downloadFile,
  sendEmail,
} from "../../../../utilities/global";
import { Container } from "@mui/system";
import { SiteContext } from "../../../../provider/site";

const Wrapper = styled(Container)`
  background: ${Colors.Gray};
  .MuiTableContainer-root {
    max-height: inherit;
  }
  .reverse {
    color: ${Colors.W100};
  }
`;

const Header = styled.div``;

const Banner = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
`;

const Title = styled(Fonts.h3())`
  justify-self: center;
  padding-bottom: 36px;
`;

const Section = styled(Stack)`
  position: relative;
  width: 100%;
  padding: 24px 16px;
`;

const ShowContentArea = styled.div`
  width: 70%;
  text-align: center;
  padding: 24px 16px;
  p {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 8px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  &.api {
    padding: 0 16px 24px;
  }
`;

const DetailBox = styled(Grid)`
  display: grid;
  gap: 12px;
  justify-content: center;
  padding: 16px;
  height: fit-content;
`;

const IconArea = styled.div`
  justify-self: center;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.W100};
  background: ${(props) => props.color || Colors.W100};
  border-radius: 100%;
`;

const Border = styled.div`
  width: 20px;
  height: 1px;
  background: ${Colors.G50};
  justify-self: center;
`;

const BoxTitle = styled(Fonts.h5())`
  text-align: center;
  padding-bottom: 8px;
`;

const BoxText = styled(Fonts.p14())`
  text-align: center;
  padding-bottom: 4px;
`;

const Footer = styled(Stack)``;

const FooterText = styled(Fonts.p12())`
  color: ${Colors.W100};
`;

const TitleSection = styled(Stack)`
  position: relative;
  width: 100%;
  padding: 40px 16px 0px;
`;

const ContentSection = styled(Stack)`
  position: relative;
  width: 100%;
  padding: 16px;
`;

const FormPreviewPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId as string;
  const id = params.id as string;
  const { token } = useContext(UserContext);
  const { formDetail, setFormDetail } = useContext(SiteContext);

  const [isPageLoading, setIsPageLoading] = useState(true);

  const [detailData, setDetailData] = useState<IWebsite>();
  const [eventDetailData, setEventDetailData] = useState<IEvent>();
  const [formData, setFormData] = useState<IFormDetail | undefined>(
    formDetail ?? undefined
  );

  const getEventDetail = () => {
    if (token) {
      ApiWithAuth(token)
        .getEventDetail(projectId, id)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.item;
              setEventDetailData(data);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  const getFormDetail = async () => {
    if (token && id) {
      try {
        const res = await ApiWithAuth(token).getEventForm(projectId, id);
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            if (!data.item?.status?.isPublished) {
              navigate(`/projects/${projectId}/events/${id}/form/add`);
            }
            setFormData(data.item);
            break;
          default:
            // alert(res.status + ": 表單不存在，請先建立報名表！");
            navigate(`/projects/${projectId}/events/${id}/form/add`);
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getWebsite = async () => {
    if (token && projectId && id) {
      try {
        const res = await ApiWithAuth(token).getWebsite(projectId, id);
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            setDetailData(data.item);
            break;
          default:
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const checkTime = (time: string) => {
    const date = new Date(time);
    const now = new Date();
    return date > now;
  };

  useEffect(() => {
    if (token) {
      getEventDetail();
      getFormDetail();
      getWebsite();
    }
  }, [token]);

  return (
    <Wrapper>
      {isPageLoading || !eventDetailData || !formData || !detailData ? (
        <Progress />
      ) : (
        <>
          <Seo
            title={eventDetailData.name}
            description={eventDetailData.name}
          />
          <Header
            style={{
              background:
                formData.colors.find((i) => i.selected === true)?.code ??
                Colors.B100,
              width: "100%",
              height: "80px",
            }}
          />
          <Banner>
            <img src={ApiNoAuth.getImg(formData.banner.fileId)} alt="banner" />
          </Banner>
          <Section justifyContent="center" alignItems="center">
            <ShowContentArea
              dangerouslySetInnerHTML={{
                __html: apiToEditor(eventDetailData.content),
              }}
            />
          </Section>
          {checkTime(eventDetailData.registrationTime.start) && (
            <Section
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              {eventDetailData.liveURL &&
                checkTime(eventDetailData.eventTime.start) && (
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      width: 160,
                      color:
                        formData.colors.find((i) => i.selected === true)
                          ?.code ?? Colors.B100,
                      borderColor:
                        formData.colors.find((i) => i.selected === true)
                          ?.code ?? Colors.B100,
                    }}
                    onClick={() => window.open(eventDetailData.liveURL)}
                  >
                    進入直播間
                  </Button>
                )}
              {checkTime(eventDetailData.registrationTime.start) && (
                <Button
                  size="large"
                  sx={{
                    width: 160,
                    background:
                      formData.colors.find((i) => i.selected === true)?.code ??
                      Colors.B100,
                  }}
                  onClick={() =>
                    window.open(`/projects/${projectId}/events/${id}/form/page`)
                  }
                >
                  立即報名
                </Button>
              )}
            </Section>
          )}
          <Section justifyContent="center" alignItems="center">
            <Title>活動資訊</Title>
            <Grid container justifyItems="center" justifyContent="center">
              <DetailBox item container xs={12} md={3}>
                <IconArea
                  color={
                    formData.colors.find((i) => i.selected === true)?.code ??
                    Colors.B100
                  }
                >
                  <FmdGoodOutlinedIcon />
                </IconArea>
                <BoxTitle>活動地址</BoxTitle>
                <Border />
                <BoxText>{eventDetailData.address}</BoxText>
              </DetailBox>
              <DetailBox item container xs={12} md={3}>
                <IconArea
                  color={
                    formData.colors.find((i) => i.selected === true)?.code ??
                    Colors.B100
                  }
                >
                  <EventOutlinedIcon />
                </IconArea>
                <BoxTitle>活動時間</BoxTitle>
                <Border />
                <BoxText>
                  {timeRangeProcessor2(
                    eventDetailData.eventTime.start,
                    eventDetailData.eventTime.end
                  )}
                </BoxText>
                <Button
                  variant="text"
                  onClick={() => {
                    createGoogleCalendarUrl(
                      eventDetailData.name,
                      eventDetailData.eventTime.start,
                      eventDetailData.eventTime.end,
                      eventDetailData.content,
                      eventDetailData.place,
                      `/${projectId}/${id}`
                    );
                  }}
                  sx={{
                    width: "min-content",
                    justifySelf: "center",
                    borderBottom: "1px solid",
                    borderRadius: 0,
                    padding: "4px 0 5px",
                    "&:hover": {
                      background: "inherit",
                      borderBottom: "2px solid",
                      padding: "4px 0",
                    },
                  }}
                >
                  <AddRoundedIcon />
                  加入行事曆
                </Button>
              </DetailBox>
              {eventDetailData.files.length > 0 && (
                <DetailBox item container xs={12} md={3}>
                  <IconArea
                    color={
                      formData.colors.find((i) => i.selected === true)?.code ??
                      Colors.B100
                    }
                  >
                    <FileDownloadOutlinedIcon />
                  </IconArea>
                  <BoxTitle>檔案下載</BoxTitle>
                  <Border />
                  {eventDetailData.files.map((i) => (
                    <Button
                      variant="text"
                      sx={{
                        width: "min-content",
                        justifySelf: "center",
                        borderBottom: "1px solid",
                        borderRadius: 0,
                        padding: "4px 0 5px",
                        "&:hover": {
                          background: "inherit",
                          borderBottom: "2px solid",
                          padding: "4px 0",
                        },
                      }}
                      onClick={() =>
                        downloadFile(ApiNoAuth.getFile(i.fileId), i.name)
                      }
                    >
                      <FileDownloadOutlinedIcon />
                      {i?.name}
                    </Button>
                  ))}
                </DetailBox>
              )}
            </Grid>
          </Section>
          <Section
            justifyItems="center"
            justifyContent="center"
            alignItems="center"
            sx={{
              background:
                formData.colors.find((i) => i.selected === true)?.code ??
                Colors.B100,
            }}
          >
            <Button
              size="large"
              sx={{
                width: 200,
                border: `1px solid`,
                mb: 2,
              }}
              color="secondary"
              variant="contained"
              onClick={() => sendEmail(eventDetailData?.email)}
            >
              聯絡我們
            </Button>
            <BoxTitle className="reverse">{eventDetailData.email}</BoxTitle>
          </Section>
          {detailData?.elements.map((i) => {
            switch (i.blockName) {
              case "core/title":
                return (
                  <TitleSection justifyItems="center" justifyContent="center">
                    <Title style={{ textAlign: "center" }}>
                      {i.attrs.title}
                    </Title>
                  </TitleSection>
                );
              case "core/content":
                return (
                  <ContentSection
                    justifyItems="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ShowContentArea
                      className="api"
                      dangerouslySetInnerHTML={{
                        __html: apiToEditor(i.attrs.content),
                      }}
                    />
                  </ContentSection>
                );
              case "core/image":
                return (
                  <Banner>
                    <img
                      src={ApiNoAuth.getImg(i.attrs?.fileId ?? "")}
                      alt="banner"
                    />
                  </Banner>
                );
              default:
                return null;
            }
          })}
          <Footer
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              background:
                formData.colors.find((i) => i.selected === true)?.code ??
                Colors.B100,
            }}
            py={2}
          >
            <FooterText>Powered By Acompany © 2021</FooterText>
            <FooterText>Privacy Plolicy</FooterText>
            <IconButton>
              <FBIcon />
            </IconButton>
            <IconButton>
              <LinkedinIcon />
            </IconButton>
          </Footer>
        </>
      )}
    </Wrapper>
  );
};

export default FormPreviewPage;
