/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo } from "react";
import { UserContext } from "../provider/user";
import styled from "styled-components";
import { Header, SideMenu, Progress } from "../components";
import Container from "@mui/material/Container";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Colors } from "../themes";

const cookies = new Cookies();

type ILayout = {
  className?: string;
  children: React.ReactNode;
};

const Wrapper = styled.div`
  background: ${Colors.BackGround};
  .MuiContainer-root {
    padding-left: 0;
    padding-right: 0;
  }
  .MuiSelect-select,
  .MuiOutlinedInput-input,
  .MuiInputBase-multiline {
    padding: 9px 16px;
  }
  .MuiOutlinedInput-input {
    padding: 9px 0px 9px 16px;
    font-size: 14px;
  }
`;

const Content = styled.div`
  position: relative;
  top: 84px;
  left: 150px;
  width: calc(100% - 150px);
  padding: 16px;
  background: ${Colors.BackGround};
  min-height: calc(100vh - 84px);
  &.withStatus {
    padding-bottom: 84px;
  }
  &.website {
    left: 0px;
    width: 100%;
    padding: 0;
  }
`;

const Layout = (props: ILayout) => {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { getToken } = useContext(UserContext);

  useEffect(() => {
    if (!pathname.includes("/login")) {
      getToken();
    }
  });
  // console.log(UserData);

  /// handle token forever
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getToken();
  //   }, 1000 * 60 * 10);

  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   if (token && cookies.get("open_status")) {
  //     openStatusStack();
  //   }
  // }, [token]);

  return (
    <Wrapper>
      <Container maxWidth={false}>
        {pathname.includes("login") ||
        pathname.includes("add") ||
        pathname.includes("preview") ||
        pathname.includes("edit") ||
        pathname.includes("form/page") ||
        pathname.includes("website/page") ? (
          <>{children}</>
        ) : (
          <>
            <Header
              name={"Molly"}
              id={"test"}
              logout={() => {}}
              isLogouting={false}
              className={pathname.includes("website") ? "website" : ""}
            />
            {pathname.includes("website") ? null : <SideMenu />}
            {false ? (
              <Content
                className={pathname.includes("website") ? "website" : ""}
              >
                <Progress />
              </Content>
            ) : (
              <Content
                className={pathname.includes("website") ? "website" : ""}
              >
                {children}
              </Content>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default Layout;
