import styled from "styled-components";
import { Control, Controller, FieldValues } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

type ITimePicker = {
  name: string;
  control: Control<FieldValues, object>;
  defaultValue?: string | null;
  rules?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  maxDateTime?: any;
  minDateTime?: any;
  disablePast?: boolean;
  key?: any;
};

const Wrapper = styled.div`
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
    max-width: 1024px;
  }
  .MuiButtonBase-root{
    padding-right: 16px;
    border-radius: 8px !important;
  }
`;

const RHFDateTimePicker = (props: ITimePicker) => {
  let defaultValue = props.defaultValue ? props.defaultValue : null;
  return (
    <Wrapper>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue}
        render={({
          field: { onChange, value },
          fieldState: { invalid, error },
        }) => {
          value && (defaultValue = value);
          return (
            <DateTimePicker
              value={defaultValue ? defaultValue : value}
              onChange={(value: any) => {
                onChange(value);
              }}
              format="yyyy/LL/dd HH:mm"
              disabled={props.disabled}
              // renderInput={(params) => (
              //   <TextField
              //     {...params}
              //     error={invalid || Boolean(error)}
              //     helperText={error?.message}
              //   />
              // )}
              ampm={false}
              minDateTime={props.minDateTime}
              maxDateTime={props.maxDateTime}
              disablePast={props.disablePast}
            />
          );
        }}
      />
    </Wrapper>
  );
};

export default RHFDateTimePicker;
