/* eslint-disable react-hooks/exhaustive-deps */
// EventDetail.tsx
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import {
  Button,
  Stack,
  IconButton,
  Box,
  Tab,
  Tabs,
  Collapse,
  TablePaginationProps,
} from "@mui/material";
import {
  DataGrid,
  GridRow,
  GridColDef,
  GridRowModel,
  GridRowProps,
  GridColumnHeaderParams,
  GridValueGetterParams,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  GridPagination,
} from "@mui/x-data-grid";

import { Progress, Select, Search, AddList, Seo } from "../../../components";
import { Colors, Fonts } from "../../../themes";
import { DeleteIcon, SendIcon } from "../../../images";

import sortList from "../../../constants/memberListSort.json";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { IEvent, IPage, IUser } from "../../../types";
import { ApiWithAuth } from "../../../service/api";
import { UserContext } from "../../../provider/user";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { timeRangeProcessor2 } from "../../../utilities/date";
import { apiToEditor } from "../../../utilities/global";
import { IBodyDeleteUser } from "../../../types/api";
import { confirm } from "../../../components/ModalF";
import { usePapaParse } from "react-papaparse";
import { useDropzone } from "react-dropzone";
import { SiteContext } from "../../../provider/site";

const rowHeight = 65;

const Wrapper = styled(Stack)`
  padding: 24px 64px;
  .MuiTableContainer-root {
    max-height: inherit;
  }
  .MuiTabs-flexContainer button {
    border-bottom: 1px solid ${Colors.B05};
  }
`;

const Title = styled(Fonts.h3())``;

const BackTitle = styled(Fonts.h4())``;

const DetailBox = styled(Box)``;

const BoxTitle = styled(Fonts.h5())`
  padding-bottom: 8px;
`;

const BoxText = styled(Fonts.p14())`
  padding-bottom: 4px;
`;

const TabContainer = styled(Stack)`
  .MuiDataGrid-footerContainer {
    justify-content: center;
    border: 0;
  }
  .MuiDataGrid-root {
    border: 0;
  }
  .MuiTablePagination-displayedRows {
    display: none;
  }
`;

const CollapseTitle = styled(Fonts.p14())`
  color: ${Colors.T5};
`;

const PaginationArea = styled.div`
  padding: 36px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  span {
    white-space: nowrap;
  }
  .current {
    font-weight: bold;
  }
`;

const EventDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId as string;
  const id = params.id as string;
  let [searchParams, setSearchParams] = useSearchParams();
  let tab = searchParams.get("tab");
  const { token } = useContext(UserContext);
  const { handleModal } = useContext(SiteContext);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isListLoading, setIsListLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const [detailData, setDetailData] = useState<IEvent>();
  const [memberList, setMemberList] = useState<IUser[]>();
  const [page, setPage] = useState<IPage>();

  const [pageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);

  const [currentTab, setCurrentTab] = React.useState(tab ?? "content");
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("0");

  const [expandedRowIds, setExpandedRowIds] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const gridRef = useRef<HTMLDivElement | null>(null);

  type RowData = {
    id: number;
    name: string;
    expanded: boolean;
  };
  const { readString } = usePapaParse();

  const onDrop = useCallback((acceptedFiles: any) => {
    readString(acceptedFiles[0], {
      worker: true,
      complete: (results) => {
        let data = results.data as any[];
        data.shift();
        let list: { name: string; phone: string; email: string }[] | undefined =
          data
            ?.map((i: string[]) => {
              if (i.length >= 3 && i[0] !== "姓名") {
                return { name: i[0], phone: "0" + i[1], email: i[2] };
              }
              return null;
            })
            .filter(
              (item): item is { name: string; phone: string; email: string } =>
                item !== null
            );
        addUsers(list);
      },
    });
  }, [token]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const getEventDetail = () => {
    if (token) {
      ApiWithAuth(token)
        .getEventDetail(projectId, id)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.item;
              setDetailData(data);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  const getEventMemberList = () => {
    if (token) {
      setIsListLoading(true);
      ApiWithAuth(token)
        .getEventMemberList(projectId, id)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data;
              setMemberList(
                data.items.map((i: any) => {
                  return {
                    ...i,
                    id: i.eventListid,
                    expanded: expandedRowIds.find(
                      (item) => item === i.eventListid
                    ),
                  };
                })
              );
              setPage(data.page);
              setIsListLoading(false);
              break;
            default:
              setIsListLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsListLoading(false);
        });
    }
  };

  const downloadCSV = () => {
    if (token) {
      ApiWithAuth(token)
        .getUserListTemplate()
        .then((res: any) => {
          switch (res.status) {
            case 200:
              const csvData = res.data;
              const csvContent =
                "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);

              // 創建一個虛擬的下載連結
              const link = document.createElement("a");
              link.href = csvContent;
              link.setAttribute("download", "template.csv");
              document.body.appendChild(link);

              // 觸發點擊事件以下載
              link.click();
              // 清除虛擬連結
              document.body.removeChild(link);

              break;
            default:
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const addUsers = async (
    memberData: { name: string; phone: string; email: string }[]
  ) => {
    try {
      if (token) {
        setIsUploading(true);
        const res = await ApiWithAuth(token).addUsers(projectId, {
          items: memberData,
        });
        const data = res?.data as any;
        switch (res.status) {
          case 200:
            getEventMemberList();
            setIsUploading(false);
            break;
          default:
            alert(data.message);
            setIsUploading(false);
        }
      }
    } catch (err) {
      console.error(err);
      setIsUploading(false);
    }
  };

  const markMember = (memberData: any) => {
    if (token && projectId) {
      delete memberData.id;
      let body = [
        {
          ...memberData,
          status: {
            isMarked: !memberData?.status?.isMarked,
          },
        },
      ];
      ApiWithAuth(token)
        .updateUsers(projectId, { items: body })
        .then((res: any) => {
          switch (res.status) {
            case 200:
              if (memberList) {
                setMemberList((prevArray) =>
                  prevArray?.map((member) =>
                    member.eventListid === memberData.eventListid
                      ? {
                          ...member,
                          status: body[0].status,
                          id: member.eventListid,
                        }
                      : member
                  )
                );
              }
              break;
            default:
              alert(res.data.message);
              break;
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const deleteUsers = (mode: string, id?: string) => {
    if (token && projectId) {
      let body: IBodyDeleteUser[] = [];
      if (mode === "multi") {
        body = selectedRows.map((i) => {
          return { eventListid: i };
        });
      } else if (id) {
        body = [{ eventListid: id }];
      }
      ApiWithAuth(token)
        .deleteUsers(projectId, body)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              getEventMemberList();

              break;
            default:
              alert(res.data.message);
              break;
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const detailBoxList = detailData
    ? [
        {
          id: "address",
          title: "活動地址",
          textList: [`${detailData.address}`],
        },
        { id: "place", title: "活動地點", textList: [`${detailData.place}`] },
        {
          id: "eventTime",
          title: "活動日期/時間",
          textList: [
            timeRangeProcessor2(
              detailData.eventTime.start,
              detailData.eventTime.end
            ),
          ],
        },
        { id: "email", title: "連絡信箱", textList: [`${detailData.email}`] },
        {
          id: "limit",
          title: "人數限制",
          textList: [`${detailData.registration.limit}`],
        },
      ]
    : [];

  const handleExpand = (id: string, expand: boolean) => {
    if (expand) {
      setExpandedRowIds((prev) => [...prev, id]);
    } else {
      setExpandedRowIds((prev) => prev.filter((rowId) => rowId !== id));
    }
    if (memberList) {
      setMemberList((prevArray) =>
        prevArray?.map((member) =>
          member.eventListid === id ? { ...member, expanded: expand } : member
        )
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `姓名 (${memberList?.length ?? 0})`,
      flex: 0.5,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <IconButton
            color="primary"
            sx={{
              "&:hover svg": { fill: Colors.Yellow },
              padding: "0 4px 0 0",
            }}
            onClick={() => {
              markMember(params?.row);
            }}
            disableFocusRipple
            disableRipple
          >
            {params?.row?.status?.isMarked ? (
              <StarRateRoundedIcon sx={{ "&": { fill: Colors.Yellow } }} />
            ) : (
              <StarOutlineRoundedIcon />
            )}
          </IconButton>
          <BoxText> {params?.row?.name}</BoxText>
        </Stack>
      ),
    },
    { field: "phone", headerName: "手機", flex: 0.5 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "des",
      headerName: "說明",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => "-",
    },
    {
      field: "download",
      headerName: "",
      renderCell: (params: GridColumnHeaderParams) => (
        <IconButton color="primary" onClick={() => {}}>
          <FileDownloadOutlinedIcon />
        </IconButton>
      ),
      sortable: false,
      width: 65,
      align: "center",
    },
    {
      field: "delete",
      headerName: "",
      renderHeader: (params: GridColumnHeaderParams) => (
        <IconButton
          color="primary"
          onClick={() => {
            deleteUsers("multi");
          }}
          disabled={selectedRows.length === 0}
        >
          <DeleteIcon />
        </IconButton>
      ),
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            deleteUsers("single", params.row.eventListid);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
      sortable: false,
      width: 65,
      align: "center",
    },
    {
      field: "expand",
      headerName: "",
      sortable: false,
      width: 65,
      align: "center",
      renderHeader: (params: GridColumnHeaderParams) => (
        <IconButton color="primary" onClick={() => {}}>
          <FileDownloadOutlinedIcon />
        </IconButton>
      ),
      renderCell: (params: { row: { expanded: any; eventListid: string } }) => {
        const expanded = params.row.expanded;
        return expanded ? (
          <IconButton
            size="small"
            onClick={() => handleExpand(params.row.eventListid, false)}
          >
            <ExpandLess />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            onClick={() => handleExpand(params.row.eventListid, true)}
          >
            <ExpandMore />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    if (token) {
      getEventDetail();
      getEventMemberList();
    }
  }, [token]);

  console.log(token);

  const CustomCollapse: React.FC<GridRowProps> = (props) => {
    return (
      <React.Fragment>
        <GridRow {...props} />
        <Collapse in={(props.row as RowData).expanded}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ padding: "16px 16px 16px 60px" }}
            bgcolor={Colors.G20}
          >
            <Stack direction="row" spacing={2}>
              <CollapseTitle>可參加時段</CollapseTitle>
              <BoxText>-</BoxText>
            </Stack>
            <Stack direction="row" spacing={2}>
              <CollapseTitle>任職單位</CollapseTitle>
              <BoxText>-</BoxText>
            </Stack>
            <Stack direction="row" spacing={2}>
              <CollapseTitle>職稱</CollapseTitle>
              <BoxText>-</BoxText>
            </Stack>
          </Stack>
        </Collapse>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (gridRef.current) {
      const virtualScroller = gridRef.current.querySelector(
        ".MuiDataGrid-virtualScrollerContent"
      );
      if (virtualScroller && virtualScroller instanceof HTMLElement) {
        if (memberList) {
          if (memberList?.length > 5) {
            virtualScroller.style.height = `${
              325 + (expandedRowIds?.length ?? 0) * rowHeight
            }px`;
          } else {
            virtualScroller.style.height = `${
              memberList?.length * rowHeight +
              (expandedRowIds?.length ?? 0) * rowHeight
            }px`;
          }
        }
      }
    }
  }, [expandedRowIds]);

  useEffect(() => {
    setExpandedRowIds([]);
  }, [currentPage]);

  function CPagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <PaginationArea>
        <span>
          {page + 1} / {pageCount}
        </span>
        <Button
          variant="text"
          onClick={(event: any) => onPageChange(event as any, page - 1)}
          disabled={page === 0}
        >
          <ArrowBackIosRoundedIcon fontSize="small" sx={{ marginRight: 2 }} />
          Previous
        </Button>
        <span className="current">{page + 1}</span>
        <Button
          variant="text"
          onClick={(event: any) => onPageChange(event as any, page + 1)}
          disabled={page + 1 > pageCount}
        >
          Next
          <ArrowForwardIosRoundedIcon fontSize="small" sx={{ marginLeft: 2 }} />
        </Button>
      </PaginationArea>
    );
  }

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={CPagination} {...props} />;
  }

  return (
    <Wrapper spacing={3}>
      {isPageLoading ? (
        <Progress />
      ) : (
        <>
          <Seo
            title={detailData?.name ?? ""}
            description={detailData?.name ?? ""}
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/projects/${projectId}`);
              }}
            >
              <ArrowBackIosNewRoundedIcon fontSize="small" />
            </IconButton>
            <BackTitle>活動列表</BackTitle>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Title>{detailData?.name}</Title>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  window.open(
                    detailData?.status.isPublished
                      ? `/projects/${id}/events/${detailData?.eventId}/form/page`
                      : `/projects/${id}/events/${detailData?.eventId}/form/preview`
                  );
                }}
              >
                報名表
              </Button>
              {detailData?.status.isSiteCreated ? (
                <Button
                  variant="outlined"
                  onClick={(event) => {
                    window.open(
                      `/projects/${id}/events/${detailData?.eventId}/website/page`
                    );
                  }}
                >
                  活動網站
                </Button>
              ) : (
                <Button
                  onClick={(event) => {
                    navigate(
                      `/projects/${id}/events/${detailData?.eventId}/website`
                    );
                  }}
                >
                  編輯活動網站
                </Button>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            {detailBoxList &&
              detailBoxList.map((i) => (
                <DetailBox key={i.id}>
                  <BoxTitle>{i.title}</BoxTitle>
                  {i.textList.map((text) => (
                    <BoxText key={text}>{text}</BoxText>
                  ))}
                </DetailBox>
              ))}
          </Stack>

          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label={`活動內容`} value="content" />
            <Tab label={`報名名單`} value="list" />
            <Tab label={`活動數據`} value="referral" disabled />
          </Tabs>
          {currentTab === "content" ? (
            <TabContainer>
              <BoxTitle>活動簡介</BoxTitle>
              <BoxText>
                {detailData ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: apiToEditor(detailData.content),
                    }}
                  />
                ) : null}
              </BoxText>
            </TabContainer>
          ) : currentTab === "list" ? (
            <TabContainer spacing={3} ref={gridRef}>
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => downloadCSV()}
                >
                  <FileDownloadOutlinedIcon />
                  CSV範本
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  disabled={isUploading}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <FileUploadOutlinedIcon />
                  上傳CSV
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    confirm({
                      className: "y-scroll",
                      title: "",
                      contentArea: <AddList projectId={projectId} id={id} />,
                      noButton: true,
                      noCancel: true,
                      handleClose: () => {
                        handleModal.close();
                        navigate(`/projects/${projectId}/events/${id}`);
                      },
                      handleConfirm: () => {},
                    })
                  }
                >
                  <SendIcon />
                  寄送邀請
                </Button>
              </Stack>
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Search
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onSubmit={() => {}}
                />
                <Select
                  value={sort}
                  onStatusChange={(e) => setSort(e.target.value)}
                  list={sortList}
                  disabled
                ></Select>
              </Stack>
              {!memberList ? null : (
                <>
                  <DataGrid
                    disableRowSelectionOnClick
                    autoHeight
                    // hideFooter
                    checkboxSelection
                    onRowSelectionModelChange={(ids: any) => {
                      setSelectedRows(ids);
                    }}
                    rows={memberList}
                    columns={columns}
                    getRowId={(row: GridRowModel) => row.id as number}
                    slots={{
                      pagination: CustomPagination,
                      row: CustomCollapse,
                    }}
                    loading={isListLoading}
                    pagination
                    pageSizeOptions={[5]}
                    // rowCount={page?.count ?? 1}
                    // paginationModel={{
                    //   pageSize: pageSize,
                    //   page: currentPage,
                    // }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    // onPaginationModelChange={setPaginationModel}
                    disableColumnFilter
                    disableColumnMenu
                    rowHeight={rowHeight}
                  />
                  {/* {page && (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      py={2}
                    >
                      <Pagination
                        page={currentPage}
                        pageSize={pageSize}
                        rowCount={memberList.length}
                        onPageChange={(newPage: number) => {
                          console.log(newPage);
                          setCurrentPage(newPage);
                        }}
                      />
                    </Stack>
                  )} */}
                </>
              )}
            </TabContainer>
          ) : null}
        </>
      )}
    </Wrapper>
  );
};

export default EventDetail;
