import { createTheme } from "@mui/material/styles";
import { Colors } from "./themes";

const theme = createTheme({
  typography: {
    fontFamily:
      '"PlusJakartaSans-VariableFont_wght", -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, Oxygen, Ubuntu, "Open Sans", "Helvetica Neue","Noto Sans TC","微軟正黑體", sans-serif',
  },
  palette: {
    primary: {
      main: Colors.Primary,
      contrastText: Colors.W100,
    },
    error: {
      main: Colors.Red,
      contrastText: Colors.W100,
    },
    secondary: {
      main: Colors.Gray,
      contrastText: Colors.B100,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: "small" },
          style: {
            padding: "0px 8px",
            lineHeight: "inherit",
            height: 30,
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: "6px 12px",
          },
        },
        {
          props: { size: "large" },
          style: {
            padding: "8px 16px",
          },
        },
        {
          props: { variant: "text", color: "primary" },
          style: {
            textTransform: "capitalize",
            "svg path": {
              fill: Colors.B100,
            },
          },
        },
        {
          props: { variant: "text", color: "primary", disabled: true },
          style: {
            "svg path": {
              fill: Colors.B30,
            },
          },
        },
        {
          props: { variant: "contained", color: "primary", disabled: true },
          style: {
            backgroundColor: Colors.T5,
            color: Colors.W100,
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            ":hover": {
              backgroundColor: Colors.B80,
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            fontWeight: 400,
          },
        },
      ],
      defaultProps: {
        variant: "contained",
        color: "primary",
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&.Mui-selected": {
            transition: 0.4,
            backgroundColor: Colors.Bule20,
          },
          "&.Mui-selected:hover": {
            backgroundColor: Colors.Bule20,
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none !important",
            },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled svg path': {
            fill: Colors.G30,
          }
        },
      },
    },
  },
});

export default theme;
