/* eslint-disable no-control-regex */
import styled from "styled-components";
import { Control, Controller, FieldValues } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Colors, Fonts, Styles } from "../../../themes";

type IRHFTextField = {
  name: string;
  label?: string | undefined;
  value?: string;
  control: Control<FieldValues, object>;
  placeholder?: string;
  defaultValue?: string;
  rules?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  limit?: number;
  suggested?: number;
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  type?: string;
  setError?: () => void;
  clearErrors?: () => void;
  inputProps?: any;
  className?: string;
  minNumber?: number;
  maxNumber?: number;
  error?: boolean;
  onBlur?: any;
  index?: number;
  variant?: "outlined" | "filled" | "standard";
  sx?: any;
};

const Wrapper = styled(Styles.MainWrapper())`
  width: 100%;
  position: relative;
  .MuiInput-underline {
    padding: 8px 16px;
    background: ${Colors.B05};
  }
  .MuiOutlinedInput-root {
    padding: 6px 0;
  }
  .MuiFormControl-root {
    width: 100%;
    max-width: 1024px;
  }
  .MuiFormHelperText-root span {
    margin: 0;
    display: inline;
  }
  .normal {
    color: ${Colors.Dark_Green};
  }
  .suggested {
    color: ${Colors.Dark_Yellow};
  }
  .limit {
    color: ${Colors.Red};
  }
  textarea {
    padding: 0 !important;
  }
  .error .Mui-error {
    text-align: left;
  }
`;

const TextArea = styled.div`
  position: relative;
  max-width: 600px !important;
  &.no-max-width {
    max-width: inherit !important;
  }
`;

const RHFTextField = (props: IRHFTextField) => {
  return (
    <Wrapper>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <TextArea
              className={`${props.className} ${
                (props.error || error?.message) && "error"
              }`}
            >
              <TextField
                sx={props.sx}
                variant={props.variant ?? "outlined"}
                autoFocus={props.autoFocus}
                onChange={onChange}
                error={props.error || error?.message ? true : false}
                inputProps={{
                  type: props.type,
                  min: props.minNumber,
                  max: props.maxNumber,
                  ...props.inputProps,
                }}
                helperText={
                  props.limit && !error?.message ? " " : error?.message ?? ""
                }
                value={props.value ?? value ?? props.defaultValue ?? ""}
                label={props.label}
                placeholder={props.placeholder}
                disabled={props.disabled}
                multiline={props.multiline}
                rows={props.rows}
                minRows={props.minRows}
                className={`${props.className} ${
                  (props.error || error?.message) && "error"
                }`}
                onBlur={(e) => {
                  if (props.onBlur) {
                    props.onBlur(e.target.value, props.index ?? 0);
                  }
                }}
              />
            </TextArea>
          );
        }}
      />
    </Wrapper>
  );
};

export default RHFTextField;
