import styled from "styled-components";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Control, FieldValues } from "react-hook-form";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

type IRHFDateTimeRangePicker = {
  name: string;
  control: Control<FieldValues, object>;
  defaultValue?: string | null;
  rules?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  maxDateTime?: any;
  minDateTime?: any;
  disablePast?: boolean;
  key?: any;
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  .MuiFormControl-root {
    width: 100%;
    max-width: 1024px;
  }
  .MuiButtonBase-root {
    padding-right: 16px;
  }
  .date-picker,
  .time-picker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .text {
    z-index: 2;
  }
`;

const RHFDateTimeRangePicker = (props: IRHFDateTimeRangePicker) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openStartTimePicker, setOpenStartTimePicker] = useState(false);
  const [openEndTimePicker, setOpenEndTimePicker] = useState(false);

  const displayDateTime = () => {
    if (selectedDate && startTime && endTime) {
      const datePart = `${selectedDate.getFullYear()}/${String(
        selectedDate.getMonth() + 1
      ).padStart(2, "0")}/${String(selectedDate.getDate()).padStart(2, "0")}`;

      const formatTime = (date: Date) =>
        `${String(date.getHours()).padStart(2, "0")}:${String(
          date.getMinutes()
        ).padStart(2, "0")}`;

      const startHourMin = formatTime(startTime);
      const endHourMin = formatTime(endTime);

      return `${datePart} ${startHourMin}-${endHourMin}`;
    }
    return "選擇日期和時間";
  };

  return (
    <Wrapper>
      <TextField
        className="text"
        value={displayDateTime()}
        onClick={() => setOpenDatePicker(true)}
        // InputProps={{
        //   readOnly: true,
        // }}
      />

      <DatePicker
        className="date-picker"
        open={openDatePicker}
        value={selectedDate}
        onChange={(date: any) => {
          setSelectedDate(date);
          setOpenDatePicker(false);
          setOpenStartTimePicker(true);
        }}
        onClose={() => setOpenDatePicker(false)}
      />

      <TimePicker
        className="time-picker"
        open={openStartTimePicker}
        value={startTime}
        onChange={(e: any) => {
          setStartTime(e);
        }}
        onClose={() => {
          setOpenStartTimePicker(false);
          setOpenEndTimePicker(true);
        }}
      />

      <TimePicker
        className="time-picker"
        open={openEndTimePicker}
        value={endTime}
        onChange={(e: any) => {
          setEndTime(e);
        }}
        onClose={() => {
          setOpenEndTimePicker(false);
        }}
      />
    </Wrapper>
  );
};

export default RHFDateTimeRangePicker;
