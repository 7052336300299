import styled from "styled-components";
import { Fonts, Styles } from "../../themes";

type IWithTitle = {
  className?: string;
  title: string;
  children: React.ReactNode;
  style?: any;
};

const Wrapper = styled(Styles.MainWrapper())`
  width: 100%;
  padding-bottom: 12px;
  div {
    outline: 0 !important;
  }
  .MuiFormHelperText-root {
    text-align: right;
  }
`;
const Title = styled(Fonts.h6())`
  padding-bottom: 4px;
  .required {
    margin-left: 4px;
  }
`;

const WithTitle = (props: IWithTitle) => {
  return (
    <Wrapper className={`${props.className}`} style={props.style}>
      <Title>
        {props.title.includes("*") ? (
          <>
            {props.title.split("*")[0]}
            <span className="required">*</span>
          </>
        ) : (
          props.title
        )}
      </Title>
      {props.children}
    </Wrapper>
  );
};

export default WithTitle;
