import styled from "styled-components";
import { Control, FieldValues, useFieldArray } from "react-hook-form";
import { Colors, Fonts } from "../../themes";
import { useEffect, useState } from "react";

import {
  Grid,
  Stack,
  IconButton,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { RHFSwitch } from "../../components";
import { CopyIcon, DeleteIcon } from "../../images";

import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";

import TextFieldsRoundedIcon from "@mui/icons-material/TextFieldsRounded";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

type IRHFFormQA = {
  className?: string;
  name: string;
  index: number;
  control: Control<FieldValues, object>;
  defaultValue?: any;
  rules?: any;
  disabled?: boolean;
  unregister: any;
  onCopy: () => void;
  onDelete: () => void;
  register: any;
  dragHandleProps: any;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Wrapper = styled.div`
  width: 100%;
`;

const QStack = styled(Stack)`
  padding: 8px 16px;
  background: ${Colors.W100};
`;

const AStack = styled(Grid)`
  padding-top: 8px;
  .MuiInput-underline {
    padding: 8px 16px;
    background: ${Colors.B05};
  }
  .MuiOutlinedInput-root {
    padding: 6px 0;
  }
  // Select
  .MuiOutlinedInput-root {
    width: 100%;
  }
  .MuiInputBase-root {
    border-radius: 8px !important;
  }
  svg,
  .MuiSvgIcon-root {
    fill: ${Colors.G5};
  }
`;
const OptionList = styled(Stack)``;
const OptionArea = styled(Stack)``;

const ControlArea = styled(Stack)`
  padding: 8px;
`;

const Text = styled(Fonts.p14())``;

const RHFFormQA = (props: IRHFFormQA) => {
  const { name, control, register, defaultValue, disabled } = props;
  const [selected, setSelected] = useState<"text" | "singleChoice">("text");
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: `${name}.${props.index}.options`,
    shouldUnregister: true,
  });

  const typeList = [
    { id: "text", label: "文字", icon: <TextFieldsRoundedIcon /> },
    {
      id: "singleChoice",
      label: "單選題",
      icon: <RadioButtonCheckedRoundedIcon />,
    },
  ];

  useEffect(() => {
    if (selected === "text") {
      props.unregister(`${name}.${props.index}.options`);
    } else {
      if (fields.length === 0) {
        append({ text: undefined });
      }
    }
  }, [selected]);

  return (
    <Wrapper className={`${props.className} ${disabled ? "disabled" : ""}`}>
      <QStack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          {...props.dragHandleProps}
          sx={{ height: "min-content" }}
        >
          <DragIndicatorRoundedIcon className="drag" />
        </IconButton>
        <TextField
          placeholder="請輸入問題"
          sx={{
            border: "none",
            "& fieldset": { border: "none" },
            width: "100%",
          }}
          defaultValue={defaultValue?.text ?? ""}
          {...register(`${name}.${props.index}.text`, { required: true })}
        />
        <IconButton>
          <MoreHorizRoundedIcon className="more" />
        </IconButton>
      </QStack>
      <AStack container rowSpacing={1} columnSpacing={2} alignItems="start">
        <Grid item xs={10}>
          {selected === "text" ? (
            <TextField
              variant="standard"
              fullWidth
              placeholder="回答"
              InputProps={{
                readOnly: true,
              }}
            />
          ) : (
            <OptionList spacing={1}>
              {fields.map((i: any, index) => (
                <OptionArea
                  key={`${i.id}-${index}`}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <TextField
                    key={`${i.id}-${index}`}
                    defaultValue={
                      defaultValue?.options
                        ? defaultValue?.options[index]?.text
                        : i.text
                    }
                    className="no-max-width"
                    variant="standard"
                    placeholder="請輸入選項"
                    sx={{
                      border: "none",
                      "& fieldset": { border: "none" },
                      width: "100%",
                    }}
                    {...register(
                      `${name}.${props.index}.options.${index}.text`,
                      { required: true }
                    )}
                  />
                  <IconButton
                    onClick={() => insert(index + 1, { text: undefined })}
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    disabled={fields.length === 0}
                    onClick={() => remove(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </OptionArea>
              ))}
            </OptionList>
          )}
        </Grid>
        <Grid item xs={2}>
          <Select
            defaultValue={defaultValue?.type ?? "text"}
            displayEmpty={true}
            renderValue={(selected: "text" | "singleChoice") => {
              setSelected(selected);
              return selected ? (
                typeList.find((i) => i.id === selected) ? (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {typeList.find((i) => i.id === selected)?.icon}
                    {typeList?.find((i) => i.id === selected)?.label}
                  </Box>
                ) : (
                  typeList?.find((i) => i.id === selected)?.label
                )
              ) : (
                ""
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                },
              },
            }}
            IconComponent={KeyboardArrowDownRoundedIcon}
            {...register(`${name}.${props.index}.type`, { required: true })}
          >
            {typeList.map(
              (i: {
                id: string;
                label: string;
                disabled?: boolean;
                icon?: any;
              }) => (
                <MenuItem key={i.id} value={i.id} disabled={i.disabled}>
                  {i.icon && <ListItemIcon>{i.icon}</ListItemIcon>}
                  <ListItemText primary={i.label} />
                </MenuItem>
              )
            )}
          </Select>
        </Grid>
      </AStack>
      <ControlArea
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={1}
      >
        <IconButton size="small" disabled={true} onClick={() => props.onCopy()}>
          <CopyIcon />
        </IconButton>
        <IconButton size="small" onClick={() => props.onDelete()}>
          <DeleteIcon />
        </IconButton>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Text>必填</Text>
          <RHFSwitch
            name={`${name}.${props.index}.required`}
            control={control}
          />
        </Stack>
      </ControlArea>
    </Wrapper>
  );
};

export default RHFFormQA;
