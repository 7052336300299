import styled from "styled-components";
import { Control, FieldValues } from "react-hook-form";
import { Colors, Fonts } from "../../themes";
import { useState } from "react";
import {
  Grid,
  Stack,
  IconButton,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { RHFUploadImage, RHFEditor } from "..";
import { CopyIcon, DeleteIcon } from "../../images";

import TextFieldsRoundedIcon from "@mui/icons-material/TextFieldsRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";

type IRHFContent = {
  className?: string;
  name: string;
  index: number;
  control: Control<FieldValues, object>;
  defaultValue?: any;
  rules?: any;
  disabled?: boolean;
  unregister: any;
  onCopy: () => void;
  onDelete: () => void;
  register: any;
  setValue: any;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Wrapper = styled.div`
  width: 100%;
`;

const AStack = styled(Grid)`
  padding-top: 4px;
  .MuiInput-underline {
    padding: 8px 16px;
    background: ${Colors.B05};
  }
  .MuiOutlinedInput-root {
    padding: 6px 0;
  }
  // Select
  .MuiOutlinedInput-root {
    width: 100%;
  }
  .MuiInputBase-root {
    border-radius: 8px !important;
  }
  svg,
  .MuiSvgIcon-root {
    fill: ${Colors.G5};
  }
`;
const OptionList = styled(Stack)``;
const OptionArea = styled(Stack)``;

const ControlArea = styled(Stack)`
  padding: 8px;
`;

const Text = styled(Fonts.p14())``;

const RHFContent = (props: IRHFContent) => {
  const { name, control, register, defaultValue, disabled } = props;
  const [selected, setSelected] = useState<
    "core/title" | "core/content" | "core/image"
  >("core/title");

  const typeList = [
    { id: "core/title", label: "標題", icon: <TitleOutlinedIcon /> },
    { id: "core/content", label: "文字", icon: <TextFieldsRoundedIcon /> },
    {
      id: "core/image",
      label: "上傳圖片",
      icon: <PhotoCameraOutlinedIcon />,
    },
  ];

  return (
    <Wrapper className={`${props.className} ${disabled ? "disabled" : ""}`}>
      <AStack container rowSpacing={1} columnSpacing={2} alignItems="start">
        <Grid item xs={10}>
          {selected === "core/title" ? (
            <TextField
              variant="standard"
              fullWidth
              placeholder="文字"
              {...register(`${name}.${props.index}.attrs.title`, {
                required: true,
              })}
            />
          ) : selected === "core/content" ? (
            <RHFEditor
              {...{
                name: `${name}.${props.index}.attrs.content`,
                control: control,
                placeholder: "輸入內容",
                rules: { required: true },
              }}
            />
          ) : (
            <RHFUploadImage
              className="full-width"
              name={`${name}.${props.index}.attrs.fileId`}
              control={control}
              setValue={props.setValue}
              aspectRatio={16 / 9}
              rules={{ require: true }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <Select
            defaultValue={defaultValue?.blockName ?? "core/title"}
            displayEmpty={true}
            renderValue={(
              selected: "core/title" | "core/content" | "core/image"
            ) => {
              setSelected(selected);
              return selected ? (
                typeList.find((i) => i.id === selected) ? (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {typeList.find((i) => i.id === selected)?.icon}
                    {typeList?.find((i) => i.id === selected)?.label}
                  </Box>
                ) : (
                  typeList?.find((i) => i.id === selected)?.label
                )
              ) : (
                ""
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                },
              },
            }}
            IconComponent={KeyboardArrowDownRoundedIcon}
            {...register(`${name}.${props.index}.blockName`, {
              required: true,
            })}
          >
            {typeList.map(
              (i: {
                id: string;
                label: string;
                disabled?: boolean;
                icon?: any;
              }) => (
                <MenuItem key={i.id} value={i.id} disabled={i.disabled}>
                  {i.icon && <ListItemIcon>{i.icon}</ListItemIcon>}
                  <ListItemText primary={i.label} />
                </MenuItem>
              )
            )}
          </Select>
        </Grid>
      </AStack>
      <ControlArea
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={1}
      >
        <IconButton size="small" disabled={true} onClick={() => props.onCopy()}>
          <CopyIcon />
        </IconButton>
        <IconButton size="small" onClick={() => props.onDelete()}>
          <DeleteIcon />
        </IconButton>
      </ControlArea>
    </Wrapper>
  );
};

export default RHFContent;
