/* eslint-disable react-hooks/exhaustive-deps */
import styled from "styled-components";
import { Fonts, Styles } from "../themes";
import { Button, Dialog, Stack } from "@mui/material";
import Slider from "@mui/material/Slider";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import getCroppedImg from "../hooks/cropImages";
import { useState, useCallback } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ApiNoAuth } from "../service/api";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { confirmable, createConfirmation } from "react-confirm";

type IModal = {
  className?: string;
  // open: boolean;
  // handleClose: () => void;
  name: string;
  show: boolean;
  proceed: any;
  file: any;
  fileType: string;
  disabled?: boolean;
  aspect?: any; // * 圖片比例
  setValue: UseFormSetValue<FieldValues>;
  cropShape?: "rect" | "round" | undefined;
};

const Wrapper = styled.div`
  padding: 16px;
  &.no-padding {
    padding: 16px 0;
  }
  position: relative;
  width: 580px;
  .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
`;

const FormAreaImg = styled(Styles.EditImg.FormAreaImg())``;
const CropperDiv = styled(Styles.EditImg.CropperDiv())``;
const ZoomDiv = styled(Styles.EditImg.ZoomDiv())``;

const UploadImgModal = (props: IModal) => {
  const [file, setFile] = useState<any>(props.file);
  const [fileType, setFileType] = useState<string>();
  // * 這邊接收上傳的檔案
  const [photoSticker, setPhotoSticker] = useState<any>(null);

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setPhotoSticker(croppedAreaPixels);
    },
    []
  );
  // * 按下上傳，確定當下的裁切，並執行後續圖片上傳流程
  const setCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(file, photoSticker, 0, fileType);
      getBlob(croppedImage);
      setFile(undefined);
      setFileType(undefined);
    } catch (e) {
      console.error(e);
    }
  }, [photoSticker, file]);

  // * 裁切後的檔案轉成 blob
  function getBlob(Img: any) {
    fetch(`${Img}`)
      .then((res) => res.blob())
      .then((blob) => {
        UploadImg(blob);
      });
  }

  // * call api 上傳圖片
  const UploadImg = async (blob: string | Blob) => {
    const formData = new FormData();
    formData.append("files", blob);
    try {
      const res = await ApiNoAuth.uploadFile(formData);
      const data = res.data as any;
      switch (res.status) {
        case 200:
        case 202:
          if (data?.item[0]) {
            props.setValue(props.name, data.item[0].id);
          }
          closeModal();
          break;
        default: {
          alert(data?.message);
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  function closeModal() {
    props.proceed(false);
    // setFile(undefined);
    // setFileType(undefined);
  }

  return (
    <Dialog open={props.show}>
      <Wrapper className={props.className}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <FormAreaImg>
            {file && (
              <>
                <CropperDiv>
                  <Cropper
                    image={file}
                    crop={crop}
                    zoom={zoom}
                    cropShape={props?.cropShape ?? "rect"}
                    aspect={props.aspect} // * 這邊是圖片比例
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </CropperDiv>
                <ZoomDiv>
                  <RemoveIcon />
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(event: Event, newValue: number | number[]) =>
                      setZoom(newValue as number)
                    }
                  />
                  <AddIcon />
                </ZoomDiv>
              </>
            )}
          </FormAreaImg>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => closeModal()}
            >
              取消
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCroppedImage()}
              disabled={!file}
            >
              上傳
            </Button>
          </Stack>
        </Stack>
      </Wrapper>
    </Dialog>
  );
};

export function confirm(confirmation: any) {
  return createConfirmation(confirmable(UploadImgModal))({
    ...confirmation,
  });
}
