import styled from "styled-components";
import { Control, Controller, FieldValues } from "react-hook-form";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { Colors } from "../../themes";

type IRHFSwitch = {
  name: string;
  control: Control<FieldValues, object>;
  defaultChecked?: boolean;
  disabled?: boolean;
};

const Wrapper = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }
`;

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: Colors.W100,
      "& + .MuiSwitch-track": {
        backgroundColor: Colors.SwitchGreen,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: Colors.SwitchGreen,
      border: `6px solid ${Colors.W100}`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: Colors.B10,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    boxShadow: `0px 2.5px 6.66667px rgba(0, 0, 0, 0.15), 0px 2.5px 0.833333px rgba(0, 0, 0, 0.05)`,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const RHFSwitch = (props: IRHFSwitch) => {
  return (
    <Wrapper>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultChecked}
        render={({ field: { onChange, value } }) => {
          let checkValue = value ?? props.defaultChecked ?? false;
          return (
            <CustomSwitch
              value={checkValue}
              onChange={onChange}
              disabled={props.disabled}
              checked={checkValue}
            />
          );
        }}
      />
    </Wrapper>
  );
};

export default RHFSwitch;
