import styled from "styled-components";
import { Control, Controller, FieldValues } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { ApiNoAuth } from "../../service/api";

type IRHFEditor = {
  name: string;
  control: Control<FieldValues, object>;
  placeholder?: string;
  defaultValue?: string;
  rules?: any;
  disabled?: boolean;
};

const Wrapper = styled.div`
  width: 100%;
  
  & > div{
    max-height: 200px;
  }
`;

const RHFEditor = (props: IRHFEditor) => {
  const { name, control, rules, defaultValue, disabled, placeholder } = props;
  return (
    <Wrapper>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            init={{
              placeholder: placeholder,
              // mode : "specific_textareas",
              // editor_selector : "mceEditor",
              statusbar: false,
              menubar: false,
              // content_css: [process.env.PUBLIC_URL + "/editor.css"],
              images_upload_handler: async (blobInfo: any) => {
                return new Promise((resolve, reject) => {
                  const formData = new FormData();
                  let blob = blobInfo.blob();
                  formData.append("file", blob);
                  ApiNoAuth.uploadFile(formData)
                    .then((data: any) => {
                      console.info("image handle upload", data);
                      console.log(
                        `domian: ${process.env.REACT_APP_API_DOMAIN}`
                      );
                      console.log(
                        `${window.location.protocol}//${window.location.host}`
                      );
                      resolve(
                        `${
                          process.env.REACT_APP_API_DOMAIN ??
                          `${window.location.protocol}//${window.location.host}`
                        }/guestapi/v1/image/${data?.data?.cover}`
                      );
                    })
                    .catch((e) => {
                      alert("upload image error");
                      reject(e);
                    });
                });
              },
            }} //*可以於此新增設定
            initialValue={defaultValue}
            value={value}
            onEditorChange={onChange}
            disabled={disabled}
            plugins={[
              // "a11ychecker",
              "advlist",
              // "advcode",
              // "advtable",
              "autolink",
              // "checklist",
              // "export",
              "lists",
              // "link",
              // "image",
              // "charmap",
              // "preview",
              // "anchor",
              // "searchreplace",
              // "visualblocks",
              // "powerpaste",
              // "fullscreen",
              // "formatpainter",
              // "template",
              // "insertdatetime",
              // "media",
              // "table",
              // "help",
              // "wordcount",
            ]}
            toolbar={
              "bold italic underline strikethrough forecolor | "
            }
            
          />
        )}
      />
    </Wrapper>
  );
};

export default RHFEditor;
