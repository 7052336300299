// Event.tsx
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Button,
  Paper,
  Stack,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Progress, Select, Seo } from "../../../components";
import { Colors, Fonts } from "../../../themes";

import sortList from "../../../constants/sortList.json";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

import { IEvent, IPage } from "../../../types";
import { ApiWithAuth } from "../../../service/api";
import { UserContext } from "../../../provider/user";
import { useNavigate, useParams } from "react-router-dom";
import { timeRangeProcessor } from "../../../utilities/date";
import { SiteContext } from "../../../provider/site";

import { confirm } from "../../../components/ModalF";

const Wrapper = styled(Stack)`
  padding: 24px 64px;
  .MuiTableContainer-root {
    max-height: inherit;
  }
`;

const Title = styled(Fonts.h3())``;

const BackTitle = styled(Fonts.h4())``;

const DataRow = styled(TableRow)`
  transition: 0.2s;
  &:last-child td,
  &:last-child th {
    border: 0;
  }
  &:hover {
    cursor: pointer;
    background: ${Colors.B05};
  }
`;

const DateText = styled(Fonts.p12())`
  color: ${Colors.G50};
`;
const UrlText = styled.a`
  color: ${Colors.Blue};
  &:hover {
    text-decoration: underline;
  }
`;

const StatusText = styled(Fonts.p14())`
  color: ${Colors.G50};
`;

const CustomMenu = styled(Menu)`
  .MuiPaper-root {
    width: 180px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px #00000014;
  }
  .MuiList-root {
    padding: 0;
  }
  .MuiMenuItem-root {
    font-size: 16px;
    line-height: 20px;
    padding: 12px 16px;
  }
`;

const Event = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const { token } = useContext(UserContext);
  const { setFormDetail } = useContext(SiteContext);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isListUpdating, setIsListUpdating] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [total, setTotal] = useState(1);
  const [nowTotal, setNowTotal] = useState(1);
  const [sort, setSort] = useState("0");

  const [title, setTitle] = useState<string>();
  const [pageList, setPageList] = useState<IEvent[] | undefined>(undefined);
  const [page, setPage] = useState<IPage>();
  const [menus, setMenus] = useState<{ [eventId: string]: HTMLElement | null }>(
    {}
  );

  const getProjectList = () => {
    if (token) {
      ApiWithAuth(token)
        .getProjects({})
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.items.find(
                (i: { projectId: string | undefined }) => i.projectId === id
              );
              setTitle(data.title);
              setIsPageLoading(false);
              break;
            default:
              setIsPageLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  const getList = () => {
    if (token && id) {
      setIsListUpdating(true);
      ApiWithAuth(token)
        .getEvents(id as string)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              setPageList(res.data.items ?? []);
              setPage(res.data.page);
              setIsListUpdating(false);
              break;
            default:
              alert(res.data.message);
              setIsListUpdating(false);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const deleteEvent = async (eventId: string) => {
    if (token && id) {
      try {
        const res = await ApiWithAuth(token).deleteEvent(id, eventId);
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            getList();
            setMenus((prevMenus) => ({
              ...prevMenus,
              [eventId]: null,
            }));
            break;
          default:
            alert(data.message);
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const postEvent = async (form: IEvent) => {
    if (token && id) {
      try {
        const submitData = {
          name: form.name,
          content: form.content,
          place: form.place,
          address: form.address,
          eventTime: form.eventTime,
          registrationTime: form.registrationTime,
          email: form.email,
          liveURL: form.liveURL ?? "",
          limit: Number(form.limit ?? 0),
          files: form.files.map((i) => {
            return { fileId: i.fileId, name: i.name };
          }),
          status: {
            isPublished: true,
            isSiteCreated: form?.status?.isSiteCreated,
          },
        };
        const res = await ApiWithAuth(token).editEvent(
          id,
          form.eventId,
          submitData
        );
        const data = res.data as any;
        switch (res.status) {
          case 200:
          case 202:
            getList();
            setMenus((prevMenus) => ({
              ...prevMenus,
              [form.eventId]: null,
            }));
            break;
          default:
            alert(data.message);
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (token) {
      getProjectList();
      getList();
    }
    setFormDetail(undefined);
  }, [token]);

  return (
    <Wrapper spacing={3}>
      {isPageLoading || !title || pageList === undefined ? (
        <Progress />
      ) : (
        <>
          <Seo title={title + "活動列表"} description={title + "活動列表"} />
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/projects`);
              }}
            >
              <ArrowBackIosNewRoundedIcon fontSize="small" />
            </IconButton>
            <BackTitle>{title}</BackTitle>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Title>活動列表</Title>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigate(`/projects/${id}/events/add`);
              }}
            >
              <AddRoundedIcon />
              新增活動
            </Button>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="end">
            <Select
              value={sort}
              onStatusChange={(e) => setSort(e.target.value)}
              list={sortList}
              disabled
            ></Select>
          </Stack>
          {isListUpdating ? (
            <Progress />
          ) : (
            <TableContainer component={Paper} sx={{ maxHeight: "inherit" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>活動名稱</TableCell>
                    <TableCell>報名人數</TableCell>
                    <TableCell>報名表</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageList &&
                    pageList.map((item) => (
                      <DataRow
                        key={item.eventId}
                        sx={{
                          transition: ".2s",
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": {
                            cursor: "pointer",
                            background: Colors.B05,
                          },
                        }}
                        onClick={() => {
                          navigate(`/projects/${id}/events/${item.eventId}`);
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: 120 }}
                        >
                          <Stack>
                            {item.name}
                            <DateText>
                              {timeRangeProcessor(
                                item.eventTime.start,
                                item.eventTime.end
                              )}
                            </DateText>
                          </Stack>
                        </TableCell>
                        <TableCell style={{ width: 80 }}>
                          {item.registration.current}/{item.registration.limit}
                        </TableCell>
                        <TableCell>
                          <Stack>
                            <UrlText
                              onClick={(event) => {
                                event.stopPropagation();
                                window.open(
                                  item.status.isPublished
                                    ? `/projects/${id}/events/${item.eventId}/form/page`
                                    : `/projects/${id}/events/${item.eventId}/form/preview`
                                );
                              }}
                            >
                              報名表
                            </UrlText>
                            <DateText>
                              {timeRangeProcessor(
                                item.registrationTime.start,
                                item.registrationTime.end
                              )}
                            </DateText>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            spacing={4}
                            justifyContent="end"
                            alignItems="center"
                          >
                            {item.status.isSiteCreated ? (
                              <Button
                                variant="outlined"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  window.open(
                                    `/projects/${id}/events/${item.eventId}/website/page`
                                  );
                                }}
                              >
                                活動網站
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  navigate(
                                    `/projects/${id}/events/${item.eventId}/website`
                                  );
                                }}
                              >
                                編輯活動網站
                              </Button>
                            )}
                            {item.status.isPublished ? (
                              <StatusText style={{ color: Colors.Green }}>
                                已發布
                              </StatusText>
                            ) : (
                              <StatusText>未發布</StatusText>
                            )}
                            <IconButton
                              color="primary"
                              onClick={(event: any) => {
                                event.stopPropagation();

                                setMenus((prevMenus) => ({
                                  ...prevMenus,
                                  [item.eventId]: event.currentTarget,
                                }));
                              }}
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                            >
                              <MoreHorizRoundedIcon />
                            </IconButton>

                            <CustomMenu
                              id="simple-menu"
                              anchorEl={menus[item.eventId]}
                              keepMounted
                              open={Boolean(menus[item.eventId])}
                              onClose={(event: any) => {
                                event.stopPropagation();
                                setMenus((prevMenus) => ({
                                  ...prevMenus,
                                  [item.eventId]: null,
                                }));
                              }}
                            >
                              {!item.status.isPublished && (
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    navigate(
                                      `/projects/${id}/events/${item.eventId}/edit`
                                    );
                                  }}
                                >
                                  編輯
                                </MenuItem>
                              )}
                              <MenuItem
                                onClick={(event) => {
                                  event.stopPropagation();
                                  confirm({
                                    title: "確定要刪除活動？",
                                    des: "刪除動作不可回復",
                                    // buttonColor: "error",
                                    handleConfirm: () =>
                                      deleteEvent(item.eventId),
                                    handleClose: () => {},
                                  });
                                }}
                              >
                                刪除
                              </MenuItem>
                              {!item.status.isPublished && (
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    confirm({
                                      title: "確定要發布活動？",
                                      des: "發布後不可編輯，如果有內容更動需重新建立活動",
                                      // buttonColor: "error",
                                      handleConfirm: () => postEvent(item),
                                      handleClose: () => {},
                                    });
                                  }}
                                >
                                  發布活動
                                </MenuItem>
                              )}
                            </CustomMenu>
                          </Stack>
                        </TableCell>
                      </DataRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Event;
