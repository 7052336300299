import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const CircularIndeterminate = (props: { height?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: props.height ?? "100vh",
        width: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CircularIndeterminate;
