/* eslint-disable react-hooks/exhaustive-deps */
// Project.tsx
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Button,
  Paper,
  Stack,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { Progress, Select, Seo } from "../../components";
import { Colors, Fonts } from "../../themes";

import sortList from "../../constants/sortList.json";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { IPage, IProject } from "../../types";
import { ApiWithAuth } from "../../service/api";
import { UserContext } from "../../provider/user";
import { useNavigate } from "react-router-dom";

const Wrapper = styled(Stack)`
  padding: 24px 64px;
  .MuiTableContainer-root {
    max-height: inherit;
  }
`;

const Title = styled(Fonts.h3())``;

const DataRow = styled(TableRow)`
  transition: 0.2s;
  &:last-child td,
  &:last-child th {
    border: 0;
  }
  &:hover {
    cursor: pointer;
    background: ${Colors.B05};
  }
`;

const Project = () => {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);

  const [total, setTotal] = useState(1);
  const [nowTotal, setNowTotal] = useState(1);
  const [sort, setSort] = useState("0");

  const [pageList, setPageList] = useState<IProject[] | undefined>(undefined);
  const [page, setPage] = useState<IPage>();

  const getList = () => {
    if (token) {
      ApiWithAuth(token)
        .getProjects({})
        .then((res: any) => {
          switch (res.status) {
            case 200:
              setPageList(res.data.items);
              setPage(res.data.page);
              setIsLoading(false);
              break;
            default:
              setIsLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (token) {
      getList();
    }
  }, [token]);

  return (
    <Wrapper spacing={3}>
      <Seo title="專案管理" description="專案管理" />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Title>
            專案管理 　{nowTotal}/{total}
          </Title>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => {}}
            disabled
          >
            加購專案
          </Button>
        </Stack>
        <Button color="primary" variant="contained" onClick={() => {}} disabled>
          <AddRoundedIcon />
          新增專案
        </Button>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Select
          value={sort}
          onStatusChange={(e) => setSort(e.target.value)}
          list={sortList}
          disabled
        ></Select>
      </Stack>
      {isLoading ? (
        <Progress />
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: "inherit" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>專案名稱</TableCell>
                <TableCell>活動數量</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageList &&
                pageList.map((item) => (
                  <DataRow
                    key={item.projectId}
                    sx={{
                      transition: ".2s",
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": { cursor: "pointer", background: Colors.B05 },
                    }}
                    onClick={() => {
                      navigate(`/projects/${item.projectId}`);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {item.title}
                    </TableCell>
                    <TableCell>1/1</TableCell>
                    <TableCell align="right">
                      <IconButton color="primary" onClick={() => {}}>
                        <MoreHorizRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </DataRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Wrapper>
  );
};

export default Project;
