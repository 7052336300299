import { useContext, useEffect, useState } from "react";
import { SiteContext } from "../provider/site";

import { confirmable, createConfirmation } from "../utilities/modal";
import { IModalF } from "../types";
import Modal from "./Modal";

// eslint-disable-next-line react/display-name
const ModalF = (props: IModalF) => {
  const Site = useContext(SiteContext);
  const { setHandleModalDetail } = Site;
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
    props.proceed(false);
  };

  useEffect(() => {
    console.log('setting');
    setHandleModalDetail({ close: handleClose });
  }, [props.show]);

  return (
    <Modal
      className={props?.className}
      open={props.show}
      handleClose={() => {
        if (props?.handleClose) {
          props.handleClose();
        }
        if (!props?.noCancelClose) {
          props.proceed(false);
        }
      }}
      title={props.title}
      des={props?.des}
      contentArea={props?.contentArea}
      buttonArea={props?.buttonArea}
      buttonText={props?.buttonText}
      cancelText={props?.cancelText}
      noCancel={props?.noCancel}
      handleConfirm={async () => {
        setIsLoading(true);

        if (props?.handleConfirm) {
          await props.handleConfirm();
          setIsLoading(false);
        }
        if (!props.noConfirmClose) {
          props.proceed(true);
        }
      }}
      isLoading={isLoading}
      disabled={props?.disabled}
      noButton={props?.noButton}
      buttonColor={props?.buttonColor}
      needFullScreen={props?.needFullScreen}
      disabledCloseButton={props?.disabledCloseButton}
    />
  );
};

export function confirm(confirmation: any) {
  return createConfirmation(confirmable(ModalF))({
    ...confirmation,
  });
}
