import styled from "styled-components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { ReactNode } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { Colors } from "../../../themes";

type IRadio = {
  id: string;
  name: string;
  title: ReactNode;
  defaultValue?: string | number | boolean;
  list: {
    value: string | number | boolean;
    label: string;
    disabled?: boolean;
  }[];
  placeholder?: string;
  hint?: string;
  disabled?: boolean;
  control: Control<FieldValues, object>;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rules: any;
  className?: string;
};

const Wrapper = styled.div`
  max-width: min-width;
  span {
    white-space: nowrap;
  }
  .form-indent-remove {
    margin-left: -8px;
  }
  .MuiFormGroup-root {
    gap: 8px;
    margin-left: 8px;
  }
  .MuiFormControlLabel-root {
    width: 300px;
    border: 1px solid ${Colors.B10};
    border-radius: 5px;
  }
`;


const RadioBtnGroup = (props: IRadio) => {
  return (
    <Wrapper>
      <FormLabel id={props.id}>{props.title}</FormLabel>
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        rules={props.rules}
        render={({ field: { onChange, value } }) => {
          let radioValue = value ?? props.defaultValue ?? "";
          return (
            <RadioGroup
              className={props.className}
              defaultValue={props.defaultValue}
              value={radioValue}
              onChange={onChange}
            >
              {props.list.map((i) => (
                <FormControlLabel
                  key={`${i.value}-${i.label}`}
                  value={i.value}
                  control={<Radio />}
                  label={i.label}
                  disabled={i.disabled}
                />
              ))}
            </RadioGroup>
          );
        }}
      />
    </Wrapper>
  );
};

export default RadioBtnGroup;
