import styled from "styled-components";
import { Fonts, Styles } from "../../themes";
import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

type IFilter = {
  title?: string;
  value: string;
  onChange: (e: any) => void;
  placeholder?: string;
  disabled?: boolean;
  onSubmit: () => void;
};

const Wrapper = styled(Styles.MainWrapper())`
  position: relative;
  .MuiInputBase-input {
    min-width: 160px;
  }
  div {
    outline: 0 !important;
    margin-right: 4px;
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
  svg:hover{
    cursor: pointer;
  }
`;

const Search = (props: IFilter) => {
  return (
    <Wrapper>
      <TextField
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder ?? "搜尋"}
        disabled={props.disabled}
      />
      <SearchOutlinedIcon color="primary" onClick={() => props.onSubmit()} />
    </Wrapper>
  );
};

export default Search;
