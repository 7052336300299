import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import {
  Button,
  Stack,
  IconButton,
  TablePaginationProps,
  CircularProgress,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridColumnHeaderParams,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  GridPagination,
} from "@mui/x-data-grid";
import { Progress, RHFTextField, WithTitle } from "../../components";
import { Colors, Fonts } from "../../themes";

import AddRoundedIcon from "@mui/icons-material/AddRounded";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import { ApiWithAuth } from "../../service/api";
import { SiteContext } from "../../provider/site";
import { useNavigate, useParams } from "react-router-dom";

import { UserContext } from "../../provider/user";
import { IEvent, IUser } from "../../types";
import { useDropzone } from "react-dropzone";
import { AddFile, Cover1, DeleteIcon } from "../../images";
import { downloadCSV } from "../../utilities/global";
import { usePapaParse } from "react-papaparse";
import { IBodyDeleteUser } from "../../types/api";
import { confirm } from "../ModalF";

const Wrapper = styled(Stack)`
  position: relative;
  max-width: 1040px;
  width: 640px;
  .MuiDataGrid-footerContainer {
    justify-content: center;
    border: 0;
  }
  .MuiOutlinedInput-root {
    background: ${Colors.G20} !important;
  }
  .MuiDataGrid-root {
    border: 0;
  }
  .MuiTablePagination-displayedRows {
    display: none;
  }
`;

const ModalTitle = styled(Fonts.h4())`
  padding-top: 0px;
`;

const ModalDes = styled(Fonts.p14())`
  padding-bottom: 8px;
  a {
    color: ${Colors.B40};
    text-decoration: none;
    padding: 0 4px;
  }
`;

const UploadCsvArea = styled(Stack)`
  width: 100%;
  padding: 50px 16px;
  border-radius: 10px;
  border: 1px solid ${Colors.B10};
  margin-top: 24px;
  margin-bottom: 24px;
  min-width: 500px;
`;

const FileText = styled(Fonts.p14())`
  padding: 0 16px 0;
  color: ${Colors.B40};
`;

const BoxTitle = styled(Fonts.h6())`
  text-align: center;
`;

const BoxText = styled(Fonts.p14())`
  text-align: center;
`;

const PaginationArea = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  span {
    white-space: nowrap;
  }
  .current {
    font-weight: bold;
  }
`;

const ListDataGrid = styled(DataGrid)`
  width: 100%;
`;

const AddList = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = props.projectId as string;
  const id = props.id;
  const { handleModal } = useContext(SiteContext);
  const { token } = useContext(UserContext);

  const [isUploading, setIsUploading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [detailData, setDetailData] = useState<IEvent>();
  const [memberList, setMemberList] = useState<IUser[]>();
  const [checkSendContentMode, setCheckSendContentMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const gridRef = useRef<HTMLDivElement | null>(null);

  const [openAddMember, setOpenAddMember] = useState(false);

  const { readString } = usePapaParse();

  const {
    handleSubmit,
    watch,
    formState: { isDirty, isValid, dirtyFields, errors },
    control,
    setValue,
    unregister,
    register,
    reset,
    trigger,
  } = useForm({ mode: "onChange" });

  const onDrop = useCallback((acceptedFiles: any) => {
    setIsUploading(true);
    readString(acceptedFiles[0], {
      worker: true,
      complete: (results) => {
        let data = results.data as any[];
        data.shift();
        let list: { name: string; phone: string; email: string }[] | undefined =
          data
            ?.map((i: string[]) => {
              if (i.length >= 3 && i[0] !== "姓名") {
                return { name: i[0], phone: "0" + i[1], email: i[2] };
              }
              return null;
            })
            .filter(
              (item): item is { name: string; phone: string; email: string } =>
                item !== null
            );
        addUsers(list);
      },
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
  });

  const getEventDetail = () => {
    if (token && id) {
      ApiWithAuth(token)
        .getEventDetail(projectId, id)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data.item;
              setDetailData(data);

              break;
            default:
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const getEventMemberList = () => {
    if (token && projectId) {
      setIsListLoading(true);
      ApiWithAuth(token)
        .getEventMemberList(projectId)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              let data = res.data;
              setMemberList(
                data.items.map((i: any) => {
                  return {
                    ...i,
                    id: i.eventListid,
                  };
                })
              );
              setIsListLoading(false);
              break;
            default:
              setIsListLoading(false);
              alert(res.data.message);
          }
        })
        .catch((err: any) => {
          console.log(err);
          setIsListLoading(false);
        });
    }
  };

  const onSubmit = async (data: any) => {
    const submitData = {
      name: data.name,
      phone: data.phone,
      email: data.email,
    };

    await addUsers([submitData]);
    reset();
  };

  const sendMail = async () => {
    try {
      if (token && memberList && id) {
        setIsLoading(true);
        const selectedUserDetail = selectedRows
          .map((id) => {
            const userProfile = memberList.find(
              (profile) => profile.eventListid === id
            );

            if (userProfile) {
              return {
                name: userProfile.name,
                email: userProfile.email,
              };
            }
            return null;
          })
          .filter(Boolean) as { name: string; email: string }[];
        if (selectedUserDetail) {
          const res = await ApiWithAuth(token).sendMail(projectId, id, {
            items: selectedUserDetail,
          });
          const data = res?.data as any;
          switch (res.status) {
            case 200:
              handleModal.close();
              setTimeout(
                () =>
                  confirm({
                    title: "",
                    contentArea: (
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        mt={-4}
                      >
                        <img src={Cover1} alt="cover-1" />
                        <ModalTitle>邀請函已發送</ModalTitle>
                        <ModalDes>
                          前往
                          <Button
                            size="small"
                            color="info"
                            variant="text"
                            onClick={() => {
                              window.location.pathname = `projects/${projectId}/events/${id}?tab=list`;
                            }}
                          >
                            名單列表
                          </Button>
                          確認報名人員名單
                        </ModalDes>
                        <Button
                          onClick={() => {
                            window.location.pathname = `projects/${projectId}/events/${id}?tab=list`;
                          }}
                          fullWidth
                        >
                          完成
                        </Button>
                      </Stack>
                    ),
                    noButton: true,
                    handleClose: () => {
                      navigate(`/projects/${projectId}/events/${id}?tab=list`);
                    },
                    handleConfirm: () => {},
                  }),
                1000
              );
              break;
            default:
              alert(data.message);
              setIsLoading(false);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const addUsers = async (
    memberData: { name: string; phone: string; email: string }[]
  ) => {
    try {
      if (token && projectId) {
        const res = await ApiWithAuth(token).addUsers(projectId, {
          items: memberData,
        });
        const data = res?.data as any;
        switch (res.status) {
          case 200:
            getEventMemberList();
            setIsUploading(false);
            break;
          default:
            alert(data.message);
            setIsUploading(false);
        }
      }
    } catch (err) {
      console.error(err);
      setIsUploading(false);
    }
  };

  const markMember = (memberData: any) => {
    if (token && projectId) {
      delete memberData.id;
      let body = [
        {
          ...memberData,
          status: {
            isMarked: !memberData?.status?.isMarked,
          },
        },
      ];
      ApiWithAuth(token)
        .updateUsers(projectId, { items: body })
        .then((res: any) => {
          switch (res.status) {
            case 200:
              if (memberList) {
                setMemberList((prevArray) =>
                  prevArray?.map((member) =>
                    member.eventListid === memberData.eventListid
                      ? {
                          ...member,
                          status: body[0].status,
                          id: member.eventListid,
                        }
                      : member
                  )
                );
              }
              break;
            default:
              alert(res.data.message);
              break;
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const deleteUsers = (mode: string, id?: string) => {
    if (token && projectId) {
      let body: IBodyDeleteUser[] = [];
      if (mode === "multi") {
        body = selectedRows.map((i) => {
          return { eventListid: i };
        });
      } else if (id) {
        body = [{ eventListid: id }];
      }
      ApiWithAuth(token)
        .deleteUsers(projectId, body)
        .then((res: any) => {
          switch (res.status) {
            case 200:
              getEventMemberList();

              break;
            default:
              alert(res.data.message);
              break;
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `姓名 (${memberList?.length ?? 0})`,
      flex: 0.5,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <IconButton
            color="primary"
            sx={{
              "&:hover svg": { fill: Colors.Yellow },
              padding: "0 4px 0 0",
            }}
            onClick={() => {
              markMember(params?.row);
            }}
            disableFocusRipple
            disableRipple
          >
            {params?.row?.status?.isMarked ? (
              <StarRateRoundedIcon sx={{ "&": { fill: Colors.Yellow } }} />
            ) : (
              <StarOutlineRoundedIcon />
            )}
          </IconButton>
          <BoxText> {params?.row?.name}</BoxText>
        </Stack>
      ),
    },
    { field: "phone", headerName: "手機", flex: 0.5 },
    { field: "email", headerName: "Email", flex: 1 },

    {
      field: "delete",
      headerName: "",
      renderHeader: (params: GridColumnHeaderParams) => (
        <IconButton
          color="primary"
          onClick={() => {
            deleteUsers("multi");
          }}
          disabled={selectedRows.length === 0}
        >
          <DeleteIcon />
        </IconButton>
      ),
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            deleteUsers("single", params.row.eventListid);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
      sortable: false,
      width: 65,
      align: "center",
    },
  ];

  function CPagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <PaginationArea>
        <span>
          {page + 1} / {pageCount}
        </span>
        <Button
          variant="text"
          onClick={(event: any) => onPageChange(event as any, page - 1)}
          disabled={page === 0}
        >
          <ArrowBackIosRoundedIcon fontSize="small" sx={{ marginRight: 2 }} />
          Previous
        </Button>
        <span className="current">{page + 1}</span>
        <Button
          variant="text"
          onClick={(event: any) => onPageChange(event as any, page + 1)}
          disabled={page + 1 > pageCount}
        >
          Next
          <ArrowForwardIosRoundedIcon fontSize="small" sx={{ marginLeft: 2 }} />
        </Button>
      </PaginationArea>
    );
  }

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={CPagination} {...props} />;
  }

  useEffect(() => {
    if (token) {
      getEventDetail();
      getEventMemberList();
    }
  }, [token, props]);

  return (
    <Wrapper
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", marginTop: -4 }}
    >
      <ModalTitle>寄送邀請函</ModalTitle>
      <ModalDes>上傳或新增您想邀請的人員名單</ModalDes>
      {checkSendContentMode ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <WithTitle title="主旨*">
            <TextField
              fullWidth
              value={detailData?.subject}
              InputProps={{
                readOnly: true,
              }}
              sx={{
                border: "none",
                "& fieldset": { border: "none" },
                width: "100%",
              }}
            />
          </WithTitle>
          <WithTitle title="內容*">
            <TextField
              fullWidth
              multiline={true}
              minRows={6}
              value={detailData?.body}
              InputProps={{
                readOnly: true,
              }}
              sx={{
                border: "none",
                "& fieldset": { border: "none" },
                width: "100%",
              }}
            />
          </WithTitle>
        </Stack>
      ) : memberList && memberList?.length > 0 ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
          spacing={1}
        >
          <Stack alignItems="end" justifyContent="end" sx={{ width: "100%" }}>
            <Button color="primary" variant="text" {...getRootProps()}>
              <input {...getInputProps()} />
              <FileUploadOutlinedIcon />
              上傳CSV
            </Button>
          </Stack>
          <ListDataGrid
            disableRowSelectionOnClick
            autoHeight
            checkboxSelection
            onRowSelectionModelChange={(ids: any) => {
              setSelectedRows(ids);
            }}
            rows={memberList}
            columns={columns}
            getRowId={(row: GridRowModel) => row.id as number}
            slots={{
              pagination: CustomPagination,
            }}
            pagination
            pageSizeOptions={[5]}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            disableColumnFilter
            disableColumnMenu
            rowHeight={65}
          />
        </Stack>
      ) : (
        <UploadCsvArea
          alignItems="center"
          justifyContent="center"
          spacing={2}
          {...getRootProps()}
        >
          {isUploading ? (
            <Progress />
          ) : (
            <>
              <input {...getInputProps()} />
              <AddFile />
              <FileText>拖曳或上傳您的檔案</FileText>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ maxWidth: "300px" }}
              >
                <BoxTitle>格式請依照範本檔案</BoxTitle>
                <BoxText>
                  請確認你的名單檔案符合「範本」檔案，確認完成後，即可上傳檔案匯入名單
                </BoxText>
              </Stack>
              <Button
                color="info"
                sx={{ borderRadius: "20px", padding: "8px 20px", zIndex: 5 }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  downloadCSV(token);
                }}
              >
                下載CSV檔案
              </Button>
            </>
          )}
        </UploadCsvArea>
      )}
      {!checkSendContentMode ?? (
        <>
          {openAddMember && (
            <Stack
              alignItems="start"
              justifyContent="start"
              sx={{ width: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
                spacing={1}
              >
                <WithTitle title="姓名 *">
                  <RHFTextField
                    name="name"
                    control={control}
                    placeholder="請輸入姓名"
                    rules={{
                      required: true,
                    }}
                    sx={{
                      border: "none",
                      "& fieldset": { border: "none" },
                      width: "100%",
                    }}
                  />
                </WithTitle>
                <WithTitle title="手機 *">
                  <RHFTextField
                    name="phone"
                    control={control}
                    placeholder="請輸入手機"
                    rules={{
                      required: true,
                      pattern: /^09\d{8}$/,
                    }}
                    sx={{
                      border: "none",
                      "& fieldset": { border: "none" },
                      width: "100%",
                    }}
                  />
                </WithTitle>
              </Stack>

              <WithTitle title="Email *">
                <RHFTextField
                  className="no-max-width"
                  name="email"
                  control={control}
                  placeholder="請輸入 Email"
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                      message: "無效的電子信箱",
                    },
                  }}
                  type="email"
                  sx={{
                    border: "none",
                    "& fieldset": { border: "none" },
                    width: "100%",
                  }}
                />
              </WithTitle>
            </Stack>
          )}
          {!openAddMember ? (
            <Stack
              alignItems="start"
              justifyContent="start"
              sx={{ width: "100%" }}
              mt={3}
            >
              <Button variant="outlined" onClick={() => setOpenAddMember(true)}>
                <AddRoundedIcon />
                手動新增
              </Button>
            </Stack>
          ) : (
            <Stack
              alignItems="start"
              justifyContent="start"
              sx={{ width: "100%" }}
              mt={1}
            >
              {openAddMember ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    disabled={!isDirty || !isValid}
                    onClick={() => handleSubmit(onSubmit)()}
                  >
                    <AddRoundedIcon />
                    新增
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setOpenAddMember(false)}
                  >
                    取消
                  </Button>
                </Stack>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setOpenAddMember(true)}
                >
                  <AddRoundedIcon />
                  新增
                </Button>
              )}
            </Stack>
          )}
        </>
      )}
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={{
          xs: 1,
          md: 2,
        }}
        sx={{
          mt: { xs: "12px", md: "24px" },
          width: "100%",
          borderTop: `1px solid ${Colors.B30}`,
          pt: "24px",
        }}
      >
        {checkSendContentMode ? (
          <>
            <Button
              size="large"
              variant="text"
              color="primary"
              onClick={() => setCheckSendContentMode(false)}
              sx={{
                minWidth: { xs: "93px" },
                height: { xs: "60px" },
              }}
            >
              返回
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => {
                confirm({
                  title: "確定寄送邀請函？",
                  des: "您的名單已自動儲存",
                  // buttonColor: "error",
                  handleConfirm: async () => await sendMail(),
                  handleClose: () => {},
                  isLoading,
                });
              }}
              sx={{
                minWidth: { xs: "93px" },
                height: { xs: "60px" },
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  寄出
                  <ArrowForwardRoundedIcon />
                </>
              )}
            </Button>
          </>
        ) : (
          <>
            <Button
              size="large"
              variant="text"
              color="primary"
              onClick={() => {
                handleModal.close();
                navigate(`projects/${projectId}/events/${id}?tab=list`);
              }}
              sx={{
                minWidth: { xs: "93px" },
                height: { xs: "60px" },
              }}
            >
              取消
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={
                !memberList || !selectedRows || selectedRows?.length === 0
              }
              onClick={() => setCheckSendContentMode(true)}
              sx={{
                minWidth: { xs: "93px" },
                height: { xs: "60px" },
              }}
            >
              下一步
              <ArrowForwardRoundedIcon />
            </Button>
          </>
        )}
      </Stack>
    </Wrapper>
  );
};

export default AddList;
