import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { Colors, Fonts } from "../themes";
import { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { UserContext } from "../provider/user";
import { Box, IconButton } from "@mui/material";
import { ProjectIcon } from "../images";

type IHeader = {
  id: string;
  name: string;
  logout: () => void;
  isLogouting?: boolean;
  className?: string;
};

const Wrapper = styled.div`
  background: ${Colors.HeaderBackground};
  position: fixed;
  width: 100vw;
  height: 84px;
  z-index: 100;
  box-shadow: 0px 4px 10px 0px #00000014;
  padding: 16px 100px 16px 16px;
  z-index: 100;
`;

const Title = styled(Fonts.h1())`
  color: ${Colors.W100};
  &:hover {
    cursor: pointer;
  }
`;

const AvatarWrapper = styled.div`
  display: inline-block;
  padding: 3px;
  background-color: white;
  border: 1px solid #d1dce2;
  border-radius: 50%;
`;

const Header = (props: IHeader) => {
  const navigate = useNavigate();

  const { UserData } = useContext(UserContext);

  // console.log(UserData)

  return (
    <Stack
      className={props.className}
      component={Wrapper}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={2}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Title></Title>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => {
            props.logout();
          }}
        >
          建立企業網站
        </Button>
        <Button
          variant="text"
          size="large"
          onClick={() => {
            props.logout();
          }}
        >
          <ProjectIcon />
          活動列表
        </Button>
        <AvatarWrapper>
          <Avatar
            sx={{ width: 28, height: 28, bgcolor: Colors.Green }}
            alt={props.name}
          >
            {/* {UserData && UserData["userName"]
              ? UserData["userName"]?.slice(0, 1)
              : props.name.slice(0, 1)} */}
          </Avatar>
        </AvatarWrapper>
      </Stack>
    </Stack>
  );
};

export default Header;
