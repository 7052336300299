import styled from "styled-components";
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { ApiNoAuth } from "../../service/api";
import { Colors, Fonts } from "../../themes";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { AddFile } from "../../images";
import { confirm } from "../UploadImgModal";

type IRHFUploadImage = {
  className?: string;
  name: string;
  control: Control<FieldValues, object>;
  des?: string;
  aspectRatio: any;
  defaultValue?: string;
  rules?: any;
  disabled?: boolean;
  setValue: UseFormSetValue<FieldValues>;
};

const Wrapper = styled.div`
  background: ${Colors.W100};
  border: 1px solid ${Colors.B30};
  border-radius: 10px;

  width: 100%;
 
  max-width: 600px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  overflow: hidden;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    background: ${Colors.B10};
    border: 1px solid transparent;
    pointer-events: none;
  }
  &.disabled:hover {
    cursor: default;
  }

  &.full-width{
    max-width: 100%;
    max-height: 270px;
    height: 56.25%;
  }
`;

const Title = styled(Fonts.p14())`
  padding: 8px 16px;
  color: ${Colors.B40};
`;

const Des = styled(Fonts.p14())`
  padding: 0 16px;
  color: ${Colors.G50};
`;

const ImageWrapper = styled.div`
  // border-radius: 10px;
  // overflow: hidden;
  margin-top: 4px;
  img {
    max-width: 600px;
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 10px;
  }
  &.full-width img {
    max-width: inherit;
  }
`;

const RHFUploadImage = (props: IRHFUploadImage) => {
  const { name, control, rules, defaultValue, disabled } = props;

  const onDrop = useCallback((acceptedFiles: any) => {
    confirm({
      aspect: props.aspectRatio,
      setValue: props.setValue,
      file: URL.createObjectURL(acceptedFiles[0]),
      fileType: acceptedFiles[0].type,
      name: name,
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
    maxFiles: 1,
  });

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            {value ? (
              <ImageWrapper  className={`${props.className} ${disabled ? "disabled" : ""}`}>
                <img src={ApiNoAuth.getImg(value)} alt="form-banner" />
              </ImageWrapper>
            ) : (
              <Wrapper
                style={{ aspectRatio: props.aspectRatio }}
                className={`${props.className} ${disabled ? "disabled" : ""}`}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <AddFile />
                <Title>拖曳或上傳您的檔案</Title>
                <Des>支援格式：jpg, png {props.des ?? ""}</Des>
              </Wrapper>
            )}
          </>
        )}
      />
    </>
  );
};

export default RHFUploadImage;
