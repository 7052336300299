import styled from "styled-components";
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { ApiNoAuth } from "../../service/api";
import { Colors, Fonts } from "../../themes";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AddFile, DeleteIcon, FileIcon, RereshIcon } from "../../images";
import {
  Box,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Stack,
  linearProgressClasses,
} from "@mui/material";

type IRHFUploadFile = {
  className?: string;
  name: string;
  control: Control<FieldValues, object>;
  placeholder?: string;
  defaultValue?: { fileId: string; name: string }[];
  rules?: any;
  disabled?: boolean;
  setValue: UseFormSetValue<FieldValues>;
};

type IFile = {
  id: string | number;
  name: string;
  size: string;
  progress: number;
  fileId?: string;
  status: "failed" | "succeed" | "uploading";
  file: any;
};

type IUploadedFile = { id?: string; name: string };

const Wrapper = styled.div`
  background: ${Colors.W100};
  border: 1px solid ${Colors.B30};
  border-radius: 10px;

  width: 100%;
  max-width: 600px;
  max-height: 270px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  overflow: hidden;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    background: ${Colors.B10};
    border: 1px solid transparent;
    pointer-events: none;
  }
  &.disabled:hover {
    cursor: default;
  }
  &.full-width {
    max-width: inherit;
  }
`;

const Title = styled(Fonts.p14())`
  padding: 8px 16px;
  color: ${Colors.B40};
`;

const Des = styled(Fonts.p14())`
  padding: 0 16px;
  color: ${Colors.G50};
`;

const FileListArea = styled(Stack)`
  padding-bottom: 16px;
`;

const FileListCell = styled(Stack)`
  background: ${Colors.W100};
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 8px 16px;
  .show-icon {
    width: 36px;
  }
  &.failed {
    border: 1px solid ${Colors.R40};
  }
`;

const CellTitle = styled(Fonts.h6())``;

const CellDes = styled(Fonts.p14())`
  color: ${Colors.G50};
  &.failed {
    color: ${Colors.R40};
  }
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "9px !important",
  borderRadius: 8,
  margin: "8px 0",
  boxShadow: `0px 1px 4px 0px ${Colors.B10}`,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: Colors.G20,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: Colors.B40,
  },

  // failed
  [`&.failed .${linearProgressClasses.bar}`]: {
    backgroundColor: Colors.Light_Red,
  },
}));

const RHFUploadFile = (props: IRHFUploadFile) => {
  const { name, control, rules, defaultValue, disabled, placeholder } = props;
  const [uploadingFiles, setUploadingFiles] = useState<IFile[]>([]);

  function bytesToSize(bytes: number): string {
    const sizes = ["bytes", "kb", "mb", "gb", "tb"];
    if (bytes === 0) return "0 Byte";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  }

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          minWidth: "200px",
        }}
      >
        <Box sx={{ width: "100%", mr: 1 }}>
          <BorderLinearProgress
            // className={props.isFailed ? "failed" : ""}
            variant="determinate"
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <CellDes>{`${props.value}%`}</CellDes>
        </Box>
      </Box>
    );
  }

  const onDrop = useCallback((acceptedFiles: any) => {
    let list = acceptedFiles?.map((file: any, index: number) => {
      return {
        id: index,
        name: file.name,
        progress: 0,
        size: bytesToSize(file.size),
        fileId: undefined,
        status: "uploading",
        file: file,
      };
    });

    setUploadingFiles((prevFiles) => [...prevFiles, ...list]);

    list.forEach((fileInfo: IFile, index: number) => {
      handleUpload(fileInfo, fileInfo.file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg", ".pdf"],
    },
  });

  const handleUpload = (fileInfo: IFile, fileObject: any) => {
    const formData = new FormData();
    formData.append("files", fileObject);

    ApiNoAuth.uploadFile(formData, {
      onUploadProgress: (progress: { loaded: number; total: number }) => {
        setUploadingFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === fileInfo.id
              ? {
                  ...file,
                  progress: Math.floor(
                    (progress.loaded / progress.total) * 100
                  ),
                  status: "uploading",
                }
              : file
          )
        );
      },
    })
      .then((res: any) => {
        switch (res.status) {
          case 200:
          case 202:
            let data = res.data.item;
            setUploadingFiles((prevFiles) =>
              prevFiles.map((file) =>
                file.id === fileInfo.id
                  ? {
                      ...file,
                      fileId: data[0].id,
                      id: data[0].id,
                      status: "succeed",
                    }
                  : file
              )
            );
            break;
          default:
            alert(res.data.message);
            setUploadingFiles((prevFiles) =>
              prevFiles.map((file) =>
                file.id === fileInfo.id
                  ? {
                      ...file,
                      fileId: undefined,
                      id: file.name + fileInfo.id,
                      status: "failed",
                    }
                  : file
              )
            );
            break;
        }
      })
      .catch((error: any) => {
        console.log(error);
        setUploadingFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === fileInfo.id
              ? {
                  ...file,
                  fileId: undefined,
                  id: file.name + fileInfo.id,
                  status: "failed",
                }
              : file
          )
        );
      });
  };

  const handleRetryUpload = (id: string | number) => {
    const targetFileInfo = uploadingFiles.find((file) => file.id === id);
    if (targetFileInfo) {
      handleUpload(targetFileInfo, targetFileInfo.file);
    }
  };

  const handleDeleteFile = (idToDelete: string | number) => {
    const updatedFiles = uploadingFiles.filter(
      (file) => file.id !== idToDelete
    );
    setUploadingFiles(updatedFiles);
  };

  useEffect(() => {
    let fileIdList = uploadingFiles
      .filter((i) => i.fileId !== undefined)
      .map((i) => {
        return { fileId: i.fileId, name: i.name };
      });
    props.setValue(name, fileIdList, { shouldValidate: true });
  }, [uploadingFiles]);

  useEffect(() => {
    if (defaultValue) {
      let list = defaultValue.map((file) => {
        return {
          fileId: file.fileId,
          id: file.fileId,

          name: file.name,
          progress: 100,
          size: "已上傳",
          file: ApiNoAuth.getImg(file.fileId),
          status: "succeed" as "failed" | "succeed" | "uploading",
        };
      });
      setUploadingFiles(list);
    }
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <FileListArea spacing={2}>
            {uploadingFiles.map((item) => (
              <FileListCell
                key={item.id}
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                className={item.status}
              >
                <Stack direction="row" spacing={1}>
                  <FileIcon className="show-icon" />
                  <Stack>
                    <CellTitle>{item.name}</CellTitle>
                    <Stack direction="row" spacing={1}>
                      <CellDes>{item.size}</CellDes>
                      {item.status === "failed" ? (
                        <CellDes>上傳失敗</CellDes>
                      ) : null}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="end" alignItems="center">
                  {item.status === "uploading" ? (
                    <LinearProgressWithLabel value={item.progress} />
                  ) : item.status === "failed" ? (
                    <IconButton onClick={() => handleRetryUpload(item.id)}>
                      <RereshIcon />
                    </IconButton>
                  ) : null}

                  {item.status === "uploading" ? null : (
                    <IconButton onClick={() => handleDeleteFile(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Stack>
              </FileListCell>
            ))}
          </FileListArea>
          <Wrapper
            style={{ aspectRatio: "3 / 2" }}
            className={`${props.className} ${disabled ? "disabled" : ""}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <AddFile />
            <Title>拖曳或上傳您的檔案</Title>
            <Des>支援格式：jpg, png, pdf</Des>
          </Wrapper>
        </>
      )}
    />
  );
};

export default RHFUploadFile;
